import React, { useEffect ,useRef} from 'react'
import DividerTop from '../../../../assets/Divider-top.svg'
import CombinedShape from '../../../../assets/Combined-Shape.svg'
import Group12 from '../../../../assets/group/Group-12.svg'
import Group13 from '../../../../assets/group/Group-13.svg'
import Group17 from '../../../../assets/group/Group-17.svg'
import Engage from '../../../../assets/group/Engage.svg'
import Engage1 from '../../../../assets/group/Engage1.svg'
import Engage2 from '../../../../assets/group/Engage2.svg'
import Engage3 from '../../../../assets/group/Engage3.svg'
import Retain from '../../../../assets/group/retain.png'
import Retain1 from '../../../../assets/group/retain-1.svg'
import Retain2 from '../../../../assets/group/retain-2.svg'
import Retain3 from '../../../../assets/group/retain-3.svg'
import Grow from '../../../../assets/group/Grow.svg'
import Grow1 from '../../../../assets/group/Grow1.svg'
import Grow2 from '../../../../assets/group/Grow2.svg'
import Grow3 from '../../../../assets/group/Grow3.svg'
import DividerBottom from '../../../../assets/Divider-bottom.svg'
import '../../../CommonFunction/Common.css'

export default function Stages() {
  const stagescontainerRef = useRef(null);
  useEffect(() => {
    const Sliders = stagescontainerRef.current.querySelectorAll(".boxslide-in");

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px"
    };

    const appearOnScroll = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("appear");
        } else {
          entry.target.classList.remove("appear");
        }
      });
    };

    const observer = new IntersectionObserver(appearOnScroll, appearOptions);

    Sliders.forEach((slider, index) => {
      slider.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(slider);
    });

    // Clean up function
    return () => {
      observer.disconnect();
    };
  }, []);


  return (
    <div>
      <img src={DividerTop} className='w-full ' />
      <div  ref={stagescontainerRef} className='bg-ghostWhite md:px-[10%] px-[2%]'>
        <h3 className=' text-2xl w-full pb-[2vh] text-center font-bold'>Success4 will help you through all stages<br />of customer success Lifecycle</h3>
        <div  className='w-full  md:flex items-center justify-center '>
          <div className='md:flex md:justify-between gap-[20px] grid grid-rows-2  '>
            <div className=' md:block flex bg-white rounded-[16px] border border-solid md:w-[50%] border-ghostWhite p-[25px] from-bottom boxslide-in '>
              <div className='box-border  '>
                <img src={CombinedShape} className='pr-[10px] mt-[2px] mr-[35px] w-[40px] h-auto inline-block' />
              </div>
              <div className='box-border '>
                <h3 className='text-[25px] mb-[7px] font-bold'>1. Understand</h3>
                <h5 className='md:text-[18px] mt-[15px] mb-[30px] font-normal text-wrap'>Gain insights on all aspects of customers, segments, usage, product adoption and your teams.</h5>
                <div className='lg:flex lg:justify-between grid md:grid-row-3 md:grid-flow-col grid-col-1 grid-flow-col'>
                  <div className='w-[137px] flex flex-col items-center'>
                    <img height='auto' width='40px' src={Group12} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Customer 360 & Health Scores</h4>
                  </div>
                  <div className='w-[137px] flex flex-col items-center'>
                    <img height='auto' width='40px' src={Group13} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Profile & Segmentation</h4>
                  </div>
                  <div className='w-[137px] flex flex-col items-center'>
                    <img height='auto' width='40px' src={Group17} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Usage Data and Analytics</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className=' md:block flex bg-white rounded-[16px] border border-solid md:w-[50%] border-ghostWhite p-[25px] from-bottom boxslide-in'>
              <div className='box-border '>
                <img src={Engage} className='pr-[10px] mt-[2px] mr-[35px] w-[40px] h-auto inline-block' />
              </div>
              <div className='box-border'>
                <h3 className='text-[25px] mb-[7px] font-bold'>2. Engage</h3>
                <h5 className='text-[18px] mt-[15px] mb-[30px] font-normal'>Better insights drive better engagement with your customers and across your organization.</h5>
                <div className='lg:flex lg:justify-between  grid md:grid-row-3 md:grid-flow-col grid-col-1 grid-flow-col '>
                  <div className='w-[120px] flex flex-col items-center'>
                    <img height='auto' width='40px' src={Engage3} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Customer Journey</h4>
                  </div>
                  <div className='w-[120px] flex flex-col items-center'>
                    <img height='auto' width='40px' src={Engage2} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Playbook Guidance</h4>
                  </div>
                  <div className='w-[120px] flex flex-col items-center'>
                    <img height='auto' width='40px' src={Engage1} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Alerts and Actions</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full  md:flex items-center justify-center py-[2vh]  '>
          <div className='md:flex md:justify-between gap-[20px] grid grid-rows-2 '>
            <div className=' md:block flex bg-white rounded-[16px] border border-solid  md:w-[50%] border-ghostWhite p-[25px]  from-bottom boxslide-in'>
              <div className='box-border '>
                <img src={Retain} className='pr-[10px] mt-[2px] mr-[35px] w-[40px] h-auto inline-block' />
              </div>
              <div className='box-border'>
                <h3 className='text-[25px] mb-[7px] font-bold'>3. Retain</h3>
                <h5 className='text-[18px] mt-[15px] mb-[30px] font-normal'>Increase retention and reduce the risk of churn with proactive monitoring and action.</h5>
                <div className='lg:flex lg:justify-between  grid md:grid-row-3 md:grid-flow-col grid-col-1 grid-flow-col '>
                  <div className='w-[120px] flex flex-col items-center '>
                    <img height='auto' width='40px' src={Retain1} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center '>Early Warning Models</h4>
                  </div>
                  <div className='w-[120px] flex flex-col items-center '>
                    <img height='auto' width='40px' src={Retain2} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center '>Renewal Blueprint</h4>
                  </div>
                  <div className='w-[120px] flex flex-col items-center '>
                    <img height='auto' width='40px' src={Retain3} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Churn Risk and Alerts</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className=' md:block flex bg-white rounded-[16px] border border-solid md:w-[50%] border-ghostWhite p-[25px] from-bottom boxslide-in'>
              <div className='box-border '>
                <img src={Grow} className='pr-[10px] mt-[2px] mr-[35px] w-[40px] h-auto inline-block' />
              </div>
              <div className='box-border'>
                <h3 className='text-[25px] mb-[7px] font-bold'>4. Grow</h3>
                <h5 className='text-[18px] mt-[15px] mb-[30px] font-normal'>Identify new & bigger opportunities for growth using our innovative data science algorithms.</h5>
                <div className='lg:flex lg:justify-between grid md:grid-row-3 md:grid-flow-col grid-col-1 grid-flow-col  '>
                  <div className='w-[120px]  flex flex-col items-center '>
                    <img height='auto' width='40px' src={Grow1} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>Upsell <br/> Triggers</h4>
                  </div>
                  <div className='w-[120px]  flex flex-col items-center '>
                    <img height='auto' width='40px' src={Grow2} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center '>AI driven Monitoring</h4>
                  </div>
                  <div className='w-[120px]  flex flex-col items-center '>
                    <img height='auto' width='40px' src={Grow3} />
                    <h4 className='text-[14px] text-blueBlack leading-[21px] font-bold text-center'>End Point Assessment</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img src={DividerBottom} className='w-full' />
    </div>
  )
}