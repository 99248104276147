import React, { useState ,useEffect} from 'react'
import Header from './Header'
import { Outlet, useNavigate } from 'react-router-dom'
import Footer from './Footer'
import Requestdemoimg from '../../assets/Requestdemo.svg'
import RequestDemo from '../../Components/CommonFunction/PopHover/RequestDemo'
import '../../Components/CommonFunction/Common.css'

const Navbar = () => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [id, setId] = useState('')

  const navigate = useNavigate()

  const toggleDropdown = (label) => {
    setOpenDropdown((prev) => (prev === label ? null : label));

  };

  const mobileToggleDropdown = (label) => {
    setOpenMobileDropdown((prev) => (prev === label ? null : label));

  };
  const hoverDropdown = (label) => {
    setIsHovered((prev) => (prev === label ? null : label));

  };

  const closeToggle = () => {
    toggleDropdown(null)
    hoverDropdown(null)

  }
  const scrollToContact = () => {
    const footerSection = document.getElementById('footer');
    if (footerSection) {
      footerSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const scrollToHeader = () => {
    const HeaderSection = document.getElementById('header');
    if (HeaderSection) {
      HeaderSection.scrollIntoView({ behavior: 'smooth' });
    }
  };



  const handleNavigation = (id, path) => {
    console.log(path)
    navigate(path)
    setId(id)
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });

    }
  };
  const handleRequestDemoClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  const [expanded, setExpanded] = useState(false);

  const handleRequestExpand = () => {
    setExpanded(!expanded);
  };
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 500);
    }, 3000); // Repeat every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='relative'>
      <div className='sticky top-0 z-50 relative bg-white ' handleNavigation={handleNavigation}>
        <Header id={id} hoverDropdown={hoverDropdown} scrollToHeader={scrollToHeader} scrollToContact={scrollToContact} toggleDropdown={toggleDropdown} mobileToggleDropdown={mobileToggleDropdown} openDropdown={openDropdown} isHovered={isHovered} openMobileDropdown={openMobileDropdown} />
      </div>
      <div id={id ? id : 'header'} onMouseEnter={closeToggle}>
        <Outlet />
      </div>
      <div onMouseEnter={closeToggle} className='relative z-40'>
        <Footer handleNavigation={handleNavigation} />
      </div>

      <div className='sticky bottom-16 z-30 absolute  flex justify-end lg:ml-[-90px] items-center w-full  '>
  <div
    className={`bg-gray-900 shadow-xl rounded-full flex   justify-between items-center cursor-pointer transition-transform duration-300 ${showAnimation && !expanded ? 'transform scale-110 rotate-6' : 'transform scale-100 rotate-0'}`}
    onMouseLeave={() => setExpanded(false)}
  >
    <div className=' flex justify-center items-center'>
      <img 
        src={Requestdemoimg} 
        className='transition-transform duration-300 hover:transform scale-110 rotate-3'
        onMouseEnter={handleRequestExpand}
      />
    </div>
    {expanded && (
      <div
        onClick={handleRequestDemoClick}
        className=' text-[#d3dfff] transition-all duration-300 pr-6 text-lg'
      >
        Request Demo
      </div>
    )}
  </div>
</div>


      <div className='flex items-center justify-center '>
        {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
      </div>
    </div>
  )
}

export default Navbar
