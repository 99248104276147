import React from 'react';
 
//This component consumed  in
//1.PageNotFoundMetaData
const PageNotFound = () => {
    return (
        <div style={{ height: '80vh', width: '100wh' }} className='flex font-bold text-[30px] text-[#454545] items-center justify-center'>
            The page you are looking for doesn't exist.
        </div>
    );
};
 
export default PageNotFound;