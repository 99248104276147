import React from 'react';
import './Scroll.css';

const Scroll = ({
  images,
  imgWidth = 'auto',
  imgHeight = 'auto',
  gap = '4',
}) => {
  

  return (
    <>
      <div class="scroll " >
        <div className="m-scroll flex items-center justify-center gap-10  ">
        
          {images.map((image, index) => (
            <span className={`w-[150px]  p-2 cursor-pointer hover:scale-105 ease-out duration-300 scrollimg`}><img
              key={index}
              src={image.src}
              alt={image.alt || ''}

              style={{ width: imgWidth, height: imgHeight, marginRight: gap }}
            />
            </span>
          ))}
        </div>
      </div>
     
    </>
  );
};

export default Scroll;
