import React,{useState} from 'react'
import RequestDemo from '../../CommonFunction/PopHover/RequestDemo';
import RequestDemoBtn from '../../CommonFunction/Button/RequestDemoBtn';

export default function PageLayout(props) {
    const { metadata = [] } = props
    const [isPopupVisible, setPopupVisible] = useState(false);
    
    const handleRequestDemoPopup = (status) => {
        setPopupVisible(status);
      };
    
      const handleClosePopup = () => {
        setPopupVisible(false);
      };
    const [isContentPopupVisible,setContentPopupVisible] =useState(false)
    const handleContentRequestDemoClick = () => {
        setContentPopupVisible(true);
      };
    
      const handleContentClosePopup = () => {
        setContentPopupVisible(false);
      };
    return (
        metadata?.map((sectionList, sectionKey) => {
            return (
                <div  key={sectionKey} className='w-full'>
                    <section className='bg-antiFlashWhite md:px-[10%] px-[2%]  md:h-[75vh]'>
                        <section className=' mr-auto ml-auto  flex flex-col '>
                            <div className='w-full '>
                                <div className='pt-[40px] '>
                                    <div className=' mr-auto ml-auto relative flex'>
                                        <div className='flex flex-col md:flex-row items-center justify-between w-full'>
                                            <div>
                                                <h3 className='md:text-3xl text-center md:text-left lg:text-6xl text-2xl font-bold '>{sectionList.headersection.h3Content}</h3>                                                
                                                 {sectionList.headersection.h6Content.map(description=>{                                                   
                                                    return(                                                                                            
                                                <h6 className='text-[20px] mt-[15px] mb-[28px] text-peaCoat'>{description.h6}</h6> 
                                               
                                                 )})} 
                                                {sectionList?.headersection?.button && <div className='mt-4 text-center md:text-left'>
                                                    <RequestDemoBtn handleRequestDemoPopup={handleRequestDemoPopup} isContainer={false} isIcon={true} h3Content={``}/>
                                                </div>}
                                            </div>
                                            <div className='relative content-start wrap p-[10px]'>
                                                <img className='w-50% h-[50vh]  align-middle inline-block' src={sectionList?.headersection?.image} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
                            {sectionList?.headersection?.horizontalLine && <div className={`w-full grid p-5 ${sectionList?.headersection?.gridCol} gap-10 border-l-[6px] wrap ${sectionList?.headersection?.horizontalLineColor}`}>
                                {sectionList?.headersection?.h3 && <p className='text-[21px] font-bold font-[700px] flex items-center justify-center'>{sectionList?.headersection?.h3}</p>}
                                <p className='text-[20px] font-[600px]'>{sectionList?.headersection?.description}</p>
                            </div>}
                        </section>
                    </section>
                    {sectionList?.bodysection?.map((bodyList, bodyKey) => {
                        return (
                            <div key={bodyKey}>
                                {bodyList?.imageAlign === 'right' && 
                                <section className='w-full md:pt-20  '>                                    
                                        <div className='px-[10%] mr-auto ml-auto relative flex '>
                                            <div className='flex md:flex-row flex-col md:items-center md:justify-between w-full'>
                                                <div className='md:w-[50%] w-full'>
                                                    <h3 className='text-3xl font-bold md:leading-[100px]'>{bodyList?.h3Content} </h3>
                                                    <h5 className='text-[21px] text-grayColor'>{bodyList?.h5Content}
                                                    </h5>
                                                </div>
                                                <div className='relative content-start wrap p-[10px] w-full md:w-[50%]' >
                                                    <img className='  align-middle inline-block' src={bodyList.image} />
                                                </div>
                                            </div>
                                        </div>                                    
                                </section>
                                }
                                {bodyList?.imageAlign === 'left' &&
                                    <section className='w-full  md:pt-20 '>                                      
                                            <div className='px-[10%] mr-auto ml-auto relative flex '>
                                                <div className='flex md:flex-row flex-col items-center justify-between w-full'>
                                                    <div className='hidden md:block relative content-start wrap p-[10px] md:w-[50%] w-full' >
                                                        <img className='  align-middle inline-block' src={bodyList.image} />
                                                    </div>
                                                    <div className='md:w-[50%] w-full'>
                                                        <h3 className='text-3xl font-bold md:leading-[100px]'>{bodyList.h3Content}</h3>
                                                        <h5 className='text-[21px] text-grayColor'>{bodyList.h5Content}
                                                        </h5>
                                                    </div>
                                                    <div className='md:hidden relative content-start wrap p-[10px] md:w-[50%] w-full' >
                                                        <img className='w-[570px] h-[471px]  align-middle inline-block' src={bodyList.image} />
                                                    </div>
                                                </div>
                                            </div>                                        
                                    </section>
                                }
                            </div>
                        )
                    })}
                    {sectionList?.howitworksection && <div className={`${sectionList?.howitworksection?.bgColor} p-10 `}>
                        <div className='w-full  '>
                            <h2 className={`text-${sectionList?.howitworksection?.textColor}  font-bold text-[24px] text-center`}>{sectionList?.howitworksection?.title}</h2>
                            <div className='pt-[40px]'>
                                <div className='md:px-[10%] '>
                                    <div className='flex items-center justify-center '>
                                        <div className={`md:grid md:${sectionList?.howitworksection?.gridCol} px-10 md:grid-flow-row md:leading-[50px] grid gap-y-10 gap-x-60`}>
                                            {sectionList?.howitworksection?.innerContent?.map((workList, workKey) => {

                                                return (
                                                <div key={workKey} className='flex flex-col justify-center items-center' >
                                                    {workList?.h1Content ? <h1 className=' text-[30px]  font-bold text-grayColor'>{workList?.h1Content}</h1>:<img  src={workList.img}/>}
                                                    <h5 className='text-[18px] md:leading-[30px] text-grayColor'>{workList?.h5Content}</h5>
                                                </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                   
                    {isContentPopupVisible && <RequestDemo onClose={handleContentClosePopup} />}
                    <div className=' md:px-[10%] px-[2%] h-[300px] mt-10'>
                    <RequestDemoBtn h3Content={sectionList?.contentbox?.h3Content}isIcon={true}  handleRequestDemoPopup={handleRequestDemoPopup} isContainer={true}/>
    </div>
                    
                       {/* <div className={` ${sectionList?.contentbox?.bgColor}   lg:h-[52vh] md:h-[50vh] rounded-[2vh] md:flex-row md:items-center md:justify-center flex flex-col pt-8 `}>
                            <div className='p-10 '>
                                <h3 className='lg:text-3xl text-2xl font-bold md:pt-15 md:pl-20 md:pb-10'>{sectionList?.contentbox?.h3Content}</h3>
                                <button className='md:ml-[11vh] lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]' onClick={handleContentRequestDemoClick}>Request Demo</button>
                            </div>
                            <img className='h-[276px] w-[580px] py-10' src={sectionList?.contentbox?.image} />
                        </div> */}
                     
                </div>
            )
        })
    )
}
