import React, {useState,useEffect} from 'react'
import PricingArc from '../../../assets/pricingarc.svg'
import PricingHand from '../../../assets/pricing-hand-1.png'
import Tick from '../../../assets/tick.svg'
import RequestDemo from '../../CommonFunction/PopHover/RequestDemo'
import './Pricing.css'

export default function Pricing() {
    const [isPopupLetsTalkVisible, setPopupLetsTalkVisible] = useState(false);

    const handleLetsTalkClick = () => {
        setPopupLetsTalkVisible(true);
    };
  
    const handleLetsTalkClosePopup = () => {
        setPopupLetsTalkVisible(false);
    };
    const [isPopupVisible, setPopupVisible] = useState(false);

    const handleRequestDemoClick = () => {
      setPopupVisible(true);
    };
  
    const handleClosePopup = () => {
      setPopupVisible(false);
    };
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // When the component mounts, set the visibility to true to trigger the animation
        setIsVisible(true);
    }, []);
    return (
        <div className='relative w-full '>
            <div className='absolute w-full'>
            <div className='h-[70vh] bg-[#D7F0E5] '>                
            </div>
            <img className=' w-full fill-[#D7F0E5]' src={PricingArc} />
            </div>
            <section className={`relative flex justify-center md:p-10 p-[3vh] ${isVisible ? 'animate-zoom-in' : ''}`}>
            <div className='md:w-[50%] md:text-center'>
                <h3 className='lg:text-5xl md:px-[10vh] text-2xl font-bold pb-[20px] text-4xl font-bold'>Pricing doesn’t have to be complicated</h3>
                <h6 className='lg:text-xl text-md font-bold '>Start simple and scale as you grow!</h6>
            </div>
        </section>
            <section className=' relative flex items-center justify-center '>
                <div className=' items-center justify-center flex w-full'>
                <div className={`bg-white w-[80%] py-[10px] md:px-[200px] grid lg:grid-row-2 lg:grid-flow-col grid-col ${isVisible ? 'animate-slide-up' : ''}`}>
            <div className='md:py-20 md:px-5 py-10 px-5 relative order-2 md:order-1'>
                <h3 className='lg:text-3xl text-2xl font-bold md:text-left text-center'>Fully Featured</h3>
                <ul className='pt-5 font-bold'>
                    <div className='flex gap-5 text-xl py-2'><img src={Tick} alt="Tick"/><li>Customer 360</li></div>
                    <div className='flex gap-5 text-xl py-1'><img src={Tick} alt="Tick"/><li>Unlimited Integrations</li></div>
                    <div className='flex gap-5 text-xl py-2'><img src={Tick} alt="Tick"/><li>External Process Automation</li></div>
                    <div className='flex gap-5 text-xl py-1'><img src={Tick} alt="Tick"/><li>Key Performance Indicators</li></div>
                    <div className='flex gap-5 text-xl py-2'><img src={Tick} alt="Tick"/><li>UI Builders</li></div>
                    <div className='flex gap-5 text-xl py-1'><img src={Tick} alt="Tick"/><li>Custom Data Attributes</li></div>
                    <div className='flex gap-5 text-xl py-2'><img src={Tick} alt="Tick"/><li>Playbooks</li></div>
                    <div className='flex gap-5 text-xl py-1'><img src={Tick} alt="Tick"/><li>Success Plans</li></div>
                    <div className='flex gap-5 text-xl py-2'><img src={Tick} alt="Tick"/><li>Email and Calendar Integrations</li></div>
                    <div className='flex gap-5 text-xl py-1 opacity-75'><img src={Tick} alt="Tick"/><li>Triggered Alerts</li></div>
                    <div className='flex gap-5 text-xl py-2 opacity-50'><img src={Tick} alt="Tick"/><li>Fully Customizable</li></div>
                    <div className='flex gap-5 text-xl py-1 opacity-25'><img src={Tick} alt="Tick"/><li>Unlimited Read-only users </li></div>
                </ul>
            </div>
            <div className='flex items-center order-1 md:order-2 justify-center md:mr-[100px]'>
                <div className='bg-white'>
                    <div className='text-center md:px-20 w-full'>
                        <div className='text-black font-bold flex md:items-start md:justify-start items-center justify-center md:ml-[30px]'>
                            <span className='text-black text-[45px] mt-[9px]'>$</span>
                            <span className='text-black text-[70px] leading-none'>99</span>
                            <span className='font-extrabold text-black h-full'>*</span>
                        </div>
                        <div className='text-xl font-normal'>per user, per month</div>
                        <p className='text-base text-[#98A2B3] py-5'>*for minimum of 5 users</p>
                        <div className='text-center'>
                            <button onClick={handleLetsTalkClick} className='my-2 mx-0 inline-block text-white rounded-md px-3 py-2 text-base bg-blueColor shadow-sm'>Let's talk</button>
                        </div>
                        {isPopupLetsTalkVisible && <RequestDemo onClose={handleLetsTalkClosePopup} />}
                    </div>
                    <div className='w-full text-center border border-t-gray border-b-gray border-l-white border-r-white p-5 mt-[40px]'>
                        <h2 className='text-xl'><span className='font-semibold text-black'>+ $89</span> per user after 5 users</h2>
                    </div>
                </div>
            </div>
        </div>
                </div>

            </section>
            <section className='flex items-center justify-center   my-[128px]'>
                        <div className={` bg-aquamarine w-full pt-10 md:pt-2 md:w-[80%] lg:h-[346px] md:h-[330px] rounded-[2vh] md:flex-row md:items-center md:justify-center flex flex-col  `}>
                            <div className='px-[32px]  flex flex-col text-left justify-start items-start  '>
                                <h3 className='lg:text-3xl text-2xl font-bold md:pl-10 md:pb-10'>Want to know how we can help you understand your customers better?</h3>
                                <button onClick={handleRequestDemoClick} className='md:ml-[2vw] lg:w-[13vw] md:w-[20vw] w-full mt-[2vh]  bg-black text-white h-[6vh] text-sm md:text-md xl:text-base rounded-[1vh] '>Request Demo</button>
                            </div>
                            <div className='w-full h-full items-center justify-center flex  md:justify-center md:items-end '>
                            <img className='h-auto w-auto  ' src={PricingHand} />
                            </div>
                        </div>
                    </section>    
                    {isPopupVisible && <RequestDemo onClose={handleClosePopup} />} 
        </div>
    )
}
