import React, { useEffect, useRef } from 'react';
import RightArrow from '../../../assets/right-arrow.png';
import '../../CommonFunction/Common.css'; // Import CSS file for animation

export default function TwoGridCol({ listData }) {
    const containerRef = useRef(null);

    useEffect(() => {
        const Sliders = containerRef.current.querySelectorAll(".slide-in");

        const appearOptions = {
            threshold: 0,
            rootMargin: "0px"
        };

        const appearOnScroll = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("appear");
                } else {
                    entry.target.classList.remove("appear");
                }
            });
        };

        const observer = new IntersectionObserver(appearOnScroll, appearOptions);

        Sliders.forEach(slider => {
            observer.observe(slider);
        });

        // Clean up function
        return () => {
            observer.disconnect();
        };
    }, [listData]);

    return (
        <section  ref={containerRef} className='flex justify-center items-center flex-col gap-24 mt-20'>
            {listData.map((data, datakey) => {
                return (
                    <div key={datakey} className='font-nunito-sans flex items-center justify-center relative w-full md:w-[80%] px-[2%] md:px-0 overflow-hidden'>
                        <div className={`grid  lg:grid-row-2 lg:grid-flow-col py-10 gap-14 `}>
                            <div className={`${data.Order.one} from-left slide-in`}>
                                <h3 className='text-[31px] font-bold pb-[10px]'>{data.Title}</h3>
                                <p className='text-[21px] font-thin text-grayColor  mb-[15px]'>{data.Description}</p>
                                <ul>
                                    {data?.List.map((listData, listIndex) => {
                                        return (
                                            <li key={listIndex} className='flex'>
                                                <img src={RightArrow} className='pr-[30px] pb-[8px] mb-[15px]' />
                                                <h4 className='text-[18px] font-semibold'>{listData}</h4>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                            <div className={`${data.Order.two} from-right slide-in`}>
                                <img src={data.Image} />
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
}
