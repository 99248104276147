import React from 'react'
import Requestdemobellicon from '../../../assets/Requestdemo.svg'
import RequestDemobg from '../../../assets/RequestDemobg.svg'

export default function RequestDemoBtn({handleRequestDemoPopup,isContainer,h3Content,isIcon}) {
  
  console.log(isContainer,isIcon,h3Content)
    const handleRequestDemoClick = () => {
        handleRequestDemoPopup(true);
    };
  
  return (
    <section >
    <div className={`${isContainer &&` w-full  bg-custom-gradient rounded-custom   `}`}>
    <div >
  <div className={`${isContainer && `xl:py-14 xl:px-14 lg:py-8 lg:px-8  px-4 py-4`} `}  >
    <div className={` ${isContainer && `flex flex-col md:flex-row justify-between items-center `}`}>
      <div className='items-center flex justify-center md:items-center md:justify-start' >
    {isContainer&&
    <div className=' flex flex-col gap-2  lg:w-[50%]  w-[65%] text-center md:text-left'>
    <p className='lg:text-2xl font-medium sm:text-2xl text-[#1D42BC] '>Want to know how we can help you <span className='lg:text-[42px] font-bold sm:text-4xl text-[#1D42BC]'>{`Understand your ${h3Content}`}</span></p>
    </div>
    }
    </div>
    <div className='p-2' >
    <div onClick={() => handleRequestDemoClick()} className={`bg-[#3E67F1]  text-white flex items-center ${isIcon?'justify-between  w-[200px]':'justify-center w-[160px]'}    ${isIcon?'pr-5':'pr-0'} rounded-md h-12 cursor-pointer`}>
        {isIcon&&<div>
          <img src={Requestdemobellicon}/>
        </div>}
        <div className='font-medium text-lg'>
          Request Demo
        </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </div>
          </section>
  )
}
