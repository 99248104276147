import Customer360Img from '../../../assets/solutions/customer360.svg'
import ImgCombinedData from '../../../assets/img_combined_customer_data-1.svg'
import CustomerCentric from '../../../assets/solutions/img_customer_centric-2.svg'
import ImgRenewal from '../../../assets/solutions/img_renewal_forecast-2.svg'
import LightBrown from '../../../assets/solutions/Light-brown-in-Jacket2-1.png'
import PreventChurn from '../../../assets/solutions/prevent_churn.svg'
import PreventChurn1 from '../../../assets/solutions/img_sustained_revenue-1.svg'
import PreventChurn2 from '../../../assets/solutions/img_delighted_customers-1.svg'
import PreventChurn3 from '../../../assets/solutions/img_prevent_surprise-1.svg'
import CustomerOnboarding from '../../../assets/solutions/customer_onboarding.svg'
import CustomerOnboarding1 from '../../../assets/solutions/img_strong_customer_relationship-1.svg'
import CustomerOnboarding2 from '../../../assets/solutions/img_product_adoption-1.svg'
import CustomerOnboarding3 from '../../../assets/solutions/img_strategic_onboarding-1.svg'
import CustomerSuccess from '../../../assets/solutions/high_touch_cs.svg'
import CustomerSuccess1 from '../../../assets/solutions/img_strategic_engagement-1.svg'
import CustomerSuccess2 from '../../../assets/solutions/img_customer_centric-2.svg'
import CustomerSuccess3 from '../../../assets/solutions/img_upsell_crosssell-2.svg'
import TechTouch from '../../../assets/solutions/tech_touch_cs.svg'
import TechTouch1 from '../../../assets/solutions/img_target_high_volume-1.svg'
import TechTouch2 from '../../../assets/solutions/img_digital_engagement-1.svg'
import CustomerExpansion from '../../../assets/solutions/customer_epansion.svg'
import CustomerExpansion2 from '../../../assets/solutions/img_increased_revenue-1.svg'
import CustomerExpansion1 from '../../../assets/solutions/img_upsell_crosssell-2.svg'
import RiskManagement from '../../../assets/solutions/risk_management.svg'
import RiskManagement1 from '../../../assets/solutions/img_customer_alignment-1.svg'
import RiskManagement2 from '../../../assets/solutions/img_convey_value-1.svg'
import RiskManagement3 from '../../../assets/solutions/img_customise_journey-1.svg'
import RiskManagement4 from '../../../assets/solutions/img_maintain_relationship-1.svg'
import RenewalManagement from '../../../assets/solutions/renewal_management.svg'
import RenewalManagement1 from '../../../assets/solutions/img_manage_renewals-1.svg'
import RenewalManagement2 from '../../../assets/solutions/img_centralised_renewal-1.svg'
import RenewalManagement3 from '../../../assets/solutions/img_renewal_forecast-2.svg'
import CustomerHealth from '../../../assets/solutions/customer_health.svg'
import CustomerHealth1 from '../../../assets/solutions/img_gain_insight-1.svg'
import CustomerHealth2 from '../../../assets/solutions/img_maximise_impact-1.svg'
import CustomerHealth3 from '../../../assets/solutions/img_benchmark_progress-1.svg'
import SuccessPlanning from '../../../assets/solutions/success_planing.svg'
import SuccessPlanning1 from '../../../assets/solutions/img_expectation_management-1.svg'
import SuccessPlanning2 from '../../../assets/solutions/img_establishing_process-1.svg'
import SuccessPlanning3 from '../../../assets/solutions/imf_measurable_goals-1.svg'
import SuccessPlanning4 from '../../../assets/solutions/img_accountability_management-1.svg'
import ProductAdoption from '../../../assets/solutions/product_adoption.svg'
import ProductAdoption1 from '../../../assets/solutions/img_gain_market-1.svg'
import ProductAdoption2 from '../../../assets/solutions/img_drive_revenue-1.svg'
import CustomerCentricSolution from '../../../assets/solutions/customer_centric_solution.svg'
import CustomerCentricSolution1 from '../../../assets/solutions/img_understand_customers-1.svg'
import CustomerCentricSolution2 from '../../../assets/solutions/img_continuous_improvement-1.svg'
import CustomerCentricSolution3 from '../../../assets/solutions/img_empower_frontline-1.svg'
import CustomerCentricSolution4 from '../../../assets/solutions/img_metrics_matters-1.svg'
import CustomerCollobration from '../../../assets/solutions/customer_collaboration.svg'
import CustomerCollobration1 from '../../../assets/solutions/img_customer_satisfaction-1.svg'
import CustomerCollobration2 from '../../../assets/solutions/img_improved_experience-1.svg'
import StakeHolder from '../../../assets/solutions/stakeholder_alignment.svg'
import StakeHolder1 from '../../../assets/solutions/img_key_contact-1.svg'
import StakeHolder2 from '../../../assets/solutions/img_customer_engagement-1.svg'
import StakeHolder3 from '../../../assets/solutions/img_anticipating_needs-1.svg'
import StakeHolder4 from '../../../assets/solutions/img_customer_value-1.svg'
import VoiceOfCustomer from '../../../assets/solutions/img_metrics_matters-1.svg'
import VoiceOfCustomer1 from '../../../assets/solutions/Make-better-decisions-1.svg'
import VoiceOfCustomer2 from '../../../assets/solutions/Optimize-the-Customer-Journey-2.svg'
import VoiceOfCustomer3 from '../../../assets/solutions/Build-Strong-Customer-Relationships-1.svg'
import AiEmail from '../../../assets/solutions/aiemail.svg'
import AiEmail1 from '../../../assets/solutions/aiemail-3.svg'
import AiEmail2 from '../../../assets/solutions/aiemail-2.svg'
import DataIntegration from '../../../assets/solutions/data_integration.svg'
import CRM from '../../../assets/product/crm-5.svg'
import ProjectManagement from '../../../assets/product/project_management-4.svg'
import UsageTracking from '../../../assets/product/usage_tracking-2.svg'
import SupportManagement from '../../../assets/product/support_management-2.svg'
import Survey from '../../../assets/product/survey-1.svg'
import SuccessplanningIcon from '../../../assets/product/img_success_planning.svg'
import CustomerCentricIcon from '../../../assets/product/img_customer_centric_solution.svg'
import HighTouchIcon from '../../../assets/product/img_high_touch.svg'
import StakeHolderIcon from '../../../assets/product/img_stakeholder_alignment.svg'
import CustomerCollobrationIcon from '../../../assets/product/img_customer_collaboration.svg'
import Customer360Icon from '../../../assets/product/img_customer_360.svg'
import ProductAdoptionIcon from '../../../assets/product/img_product_adoption-1.svg'
import PreventChurnIcon from '../../../assets/product/img_prevent_churn.svg'
import TechTouchIcon from '../../../assets/product/img_tech_touch_cs.svg'
import customerHealthIcon from '../../../assets/product/img_customer_health.svg'
import RenewalManagementIcon from '../../../assets/product/img_renewal_management.svg'
import CustomerOnboardingIcon from '../../../assets/product/img_customer_onboarding.svg'
import RiskManagementIcon from '../../../assets/product/img_risk_management.svg'
import CustomerExpansionIcon from '../../../assets/product/img_customer_expansion.svg'
import VoiceCustomerIcon from '../../../assets/product/img_voice_customer.svg'
import UIBuilder from '../../../assets/product/ui_Builders.svg'
import UIComponent from '../../../assets/product/img_ui_component-1.svg'
import UIDashboard from '../../../assets/product/img_ui_dashboard-1.svg'
import DashboardScope from '../../../assets/product/img_dashboard_scope-1.svg'
import DashboardRoles from '../../../assets/product/img_dashboard_roles-1.svg'
import TriggeredAlerts from '../../../assets/product/triggered_alert.svg'
import Rules from '../../../assets/product/img_rules-1.svg'
import Alerts from '../../../assets/product/img_alert-1.svg'
import PlaybookAssignment from '../../../assets/product/img_automated_playbook_assignment-1.svg'
import ScheduleTrigger from '../../../assets/product/img_schedule_triggers-1.svg'
import EPA from '../../../assets/product/epa.svg'
import Email from '../../../assets/product/img_email_calender-1.svg'
import PlaybookCustomIntegration from '../../../assets/product/img_playbook_custom_integration-1.svg'
import RestApi from '../../../assets/product/img_custom_rest_api-1.svg'
import Playbook from '../../../assets/product/playbook.svg'
import Action from '../../../assets/product/actions-1.svg'
import AssetsNotes from '../../../assets/product/assets_notes.svg'
import ExternalProcessAutomation from '../../../assets/product/external_process_automation-1.svg'
import ActionTimeline from '../../../assets/product/action_timeline.svg'
import CustomDataAttribute from '../../../assets/product/custom_data_attribute.svg'
import CaptureQualitative from '../../../assets/product/img_capture_qualitative_data-1.svg'
import CustomForm from '../../../assets/product/img_custom_form_field-1.svg'
import ReadWritePermission from '../../../assets/product/img_read_write_permission-1.svg'
import EmailIntegration from '../../../assets/product/email_calendar_integration.svg'
import Google from '../../../assets/product/img_google-1.svg'
import Office from '../../../assets/product/img_office_365-1.svg'
import KeyPerformance from '../../../assets/product/kpi.svg'
import Trending from '../../../assets/product/trending.svg'
import ProgressBar from '../../../assets/product/progress_bar.svg'
import Distribution from '../../../assets/product/distribution.svg'
import KPIScope from '../../../assets/product/img_kpi_scopes-1.svg'
import SuccessPlan from '../../../assets/product/success_plan.svg'
import Objectives from '../../../assets/product/objectives-3.svg'
import SuccessPlanAction from '../../../assets/product/actions.svg'
import ExternalSuccessplan from '../../../assets/product/external_process_automation-1.svg'
import AssetsSuccessPlan from '../../../assets/product/assets-notes.svg'
import SponsorTracking from '../../../assets/product/sponsor_tracking.svg'
import SponsorList from '../../../assets/product/sponsor_list.svg'
import ContactNotes from '../../../assets/product/contact_notes.svg'
import GlobalTag from '../../../assets/product/img_global_tags-1.svg'
import OrganizationChart from '../../../assets/product/organization_chart-1.svg'
import CustomerSuccessManagerPersonas from '../../../assets/Personas/customer_success_manager.svg'
import PlaybookPersonas from '../../../assets/Personas/playbook_guidance_health_score.svg'
import ExternalProcessAutomationPersonas from '../../../assets/Personas/external_process_automation-1.svg'
import EmailPersonas from '../../../assets/Personas/email_calendar_integrations.webp'
import GrowBusiness from '../../../assets/Personas/image.png'
import QuantifyCs from '../../../assets/Personas/quantify_cs.svg'
import OwnershipCs from '../../../assets/Personas/ownership_cs.svg'
import CrossFunctionalTeam from '../../../assets/Personas/cross_functional_teams.svg'
import ExecutiveCXO from '../../../assets/Personas/executives-_cxo_vp.svg'
import UIBuilderPersonas from '../../../assets/Personas/ui_builder_bi_Integrations.svg'
import SecurityComplaince from '../../../assets/Personas/security_compliance.svg'
import CustomerOperation from '../../../assets/Personas/customer_success_operations.svg'
import TwoWayCRM from '../../../assets/Personas/two_way_sync_with_your_crm.svg'
import RoleBasedAccess from '../../../assets/Personas/role_based_access_control.svg'
import PromoteService from '../../../assets/Personas/promote_service.svg'
import SeamlessDataIntegrity from '../../../assets/Personas/seamless_data_integrity.svg'

export const pages = {

    customer360: [
        {
            headersection: {
                image: Customer360Img,
                h3Content: 'Customer 360',
                h6Content: [{h6:'Understand your Customers'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Build a complete view of your customers and deliver a consistent and seamless experience for them. With Success4, you can build a unified customer profile, easily integrating all your customer interactions and data. Ensure everyone in your organization has a full view of your customers.'
            },
            bodysection: [
                {
                    image: ImgCombinedData,
                    imageAlign: 'right',
                    h3Content: 'Unify your Customer Data',
                    h5Content: 'Deliver timely information automatically assembled from all your relevant data sources, internal and external.'
                },
                {
                    image: CustomerCentric,
                    imageAlign: 'left',
                    h3Content: 'Understand Each Customer',
                    h5Content: 'Have a complete view of your customer’s behavior and trends to provide the best personalized customer experience possible'
                },
                {
                    image: ImgRenewal,
                    imageAlign: 'right',
                    h3Content: 'Empower your Teams',
                    h5Content: 'Deliver a seamless customer experience in every interaction by ensuring everyone in your organization has a consistent view of the customer.'
                },
            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Data Integration',
                        h5Content: 'Easily integrate all your customer data'
                    },
                    {
                        h1Content: 'Custom Data Type',
                        h5Content: 'Capture qualitative and quantitative assessments	'
                    },
                    {
                        h1Content: 'KPIs',
                        h5Content: 'Measure and track the most important indicators'
                    },
                    {
                        h1Content: 'Health Trending',
                        h5Content: 'Derive trends in health scores'
                    },
                    {
                        h1Content: 'Unlimited Read-Only Users',
                        h5Content: ''
                    },
                ],
            },
            contentbox: {
                h3Content: 'customers',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    preventchurn: [
        {
            headersection: {
                image: PreventChurn,
                h3Content: 'Prevent Churn',
                h6Content: [{h6:'Increase Customer Retention'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Know your at-risk customer, engage and educate them, offer incentives, provide better service, track execution, get results, and much more with Success4.'
            },
            bodysection: [
                {
                    image: PreventChurn1,
                    imageAlign: 'right',
                    h3Content: 'Sustained Revenue',
                    h5Content: 'Increase revenue retention by proactively addressing at-risk customers.'
                },
                {
                    image: PreventChurn2,
                    imageAlign: 'left',
                    h3Content: 'Delighted Customers',
                    h5Content: 'Build loyalty by helping your customers recognize the full value you provide'
                },
                {
                    image: PreventChurn3,
                    imageAlign: 'right',
                    h3Content: 'Prevent Surprise',
                    h5Content: 'Know your customers better. Manage your business more effectively'
                },
            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Track Customer Usage',
                        h5Content: 'Easily integrate your customer usage data.'
                    },
                    {
                        h1Content: 'Predictive Analytics',
                        h5Content: 'Monitor health to proactively identify risks.'
                    },
                    {
                        h1Content: 'Track Customer Health',
                        h5Content: 'Transform data to customer health insights.'
                    },
                    {
                        h1Content: 'Triggered Alerts',
                        h5Content: 'Take action to address risk.'
                    },
                    {
                        h1Content: 'Best Practice Playbooks',
                        h5Content: 'Consistently execute at scale.'
                    },
                ],
            },
            contentbox: {
                h3Content: 'Prevent Churn?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    customeronboarding: [
        {
            headersection: {
                image: CustomerOnboarding,
                h3Content: 'Customer Onboarding',             
                h6Content: [{h6:'Tailor the Best Customer Onboarding Experience'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-3',
                description: 'Onboarding isn’t just about teaching new users which buttons to click to use your app. People care more about outcomes than features, and it’s your job to help them reach those outcomes as easily and predictably as possible.'
            },
            bodysection: [
                {
                    image: CustomerOnboarding1,
                    imageAlign: 'right',
                    h3Content: 'Strong Customer Relationship',
                    h5Content: 'Onboarding sets the tone for your business relationship with buyers and users.'
                },
                {
                    image: CustomerOnboarding2,
                    imageAlign: 'left',
                    h3Content: 'Product Adoption',
                    h5Content: 'Customized tailored onboarding Experience ensures strong Product adoption in future.'
                },
                {
                    image: CustomerOnboarding3,
                    imageAlign: 'right',
                    h3Content: 'Strategic',
                    h5Content: 'Customer onboarding is the most strategic step in the customer success journey. This leads to a better ROI.'
                },
            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Customer Lifecycle',
                        h5Content: 'Understand, Engage, Retain, and Grow your customers'
                    },
                    {
                        h1Content: 'Customer Portal',
                        h5Content: 'Create customer portal pages for your customers and collaborate'
                    },
                    {
                        h1Content: 'Playbooks',
                        h5Content: 'Consistently execute at scale'
                    },
                    {
                        h1Content: 'Digital Workflows',
                        h5Content: 'Automate the repeating aspect of the customer success process'
                    },
                ],
            },
            contentbox: {
                h3Content: 'Customer Onboarding',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    hightouchcustomersuccess: [
        {
            headersection: {
                image: CustomerSuccess,
                h3Content: 'High Touch Customer Success',
                h6Content: [{h6:'Engage your High-Value Customers Effectivel'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'In SaaS, the sale never really ends; Helping customers on a human level through various stages of the buying process and lifecycle. It involves much higher participation, and usually relies on one individual or team within the company to maintain direct, personal, and frequent contact with accounts.'
            },
            bodysection: [
                {
                    image: CustomerSuccess1,
                    imageAlign: 'right',
                    h3Content: 'Strategic Conversations/Engagement',
                    h5Content: 'Use a high touch approach to focus your engagement around customer needs and your business goals.'
                },
                {
                    image: CustomerSuccess2,
                    imageAlign: 'left',
                    h3Content: 'Customer-Centric',
                    h5Content: 'Convey the value of the product Centered around customer needs , use cases, and pain points.'
                },
                {
                    image: CustomerSuccess3,
                    imageAlign: 'right',
                    h3Content: 'Cross-Sell and Up-Sell',
                    h5Content: 'Enable your customers to help themselves purchases more licenses across your product line.'
                },
            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Data Integrations',
                        h5Content: 'Easily integrate your customer data'
                    },
                    {
                        h1Content: 'Success Plans',
                        h5Content: 'Tailor-made success plan for high touch customer success'
                    },
                    {
                        h1Content: 'Customer Portal',
                        h5Content: 'Create customer portals for your customer and collaborate'
                    },
                    {
                        h1Content: 'Playbooks',
                        h5Content: 'Consistently execute at scale'
                    },
                    {
                        h1Content: 'Advanced Reporting',
                        h5Content: ''
                    },
                ],
            },
            contentbox: {
                h3Content: 'High Touch Customers',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    techtouchcustomersuccess: [
        {
            headersection: {
                image: TechTouch,
                h3Content: 'Tech Touch Customer Success',
                h6Content: [{h6:'Automate and Scale the Customer Reach'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'With tech-touch engagements, customer success teams can operate as proactive encouragement for the customer to try new features in anticipation of future opportunities. The approach can be a better fit for the digitally native generations of B2B customers that prefer to find their own solutions.'
            },
            bodysection: [
                {
                    image: TechTouch2,
                    imageAlign: 'right',
                    h3Content: 'Digital Customer Engagement',
                    h5Content: 'Automate engagement To reach all your customers And scale effectively.'
                },
                {
                    image: TechTouch1,
                    imageAlign: 'left',
                    h3Content: 'Target High Customer Volume',
                    h5Content: 'Reach high customers volumes with Low recurring revenue and and/or Uncomplicated rollout.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Data Integrations',
                        h5Content: 'Easily integrate your customer data'
                    },
                    {
                        h1Content: 'Triggered Alerts',
                        h5Content: 'Take action to address risks and opportunities'
                    },
                    {
                        h1Content: 'Best Practice Playbooks',
                        h5Content: 'Manage your important documents and notes for each action.'
                    },
                    {
                        h1Content: 'Predictive Analytics',
                        h5Content: 'Derive insights from the captured data'
                    },
                    {
                        h1Content: 'Digital Workflows',
                        h5Content: 'Automate the repeating aspect of the customer success process'
                    },
                    {
                        h1Content: 'Resources (JIT Learning)',
                        h5Content: 'Manage your important documents and notes for each action.'
                    },
                ],
            },
            contentbox: {
                h3Content: 'automate and scale customer reach',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    customerexpansion: [
        {
            headersection: {
                image: CustomerExpansion,
                h3Content: 'Customer Expansion',
                h6Content: [{h6:'Execute on cross-sell and up-sell opportunities'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'A sale isn’t over just because your prospect becomes a customer. There’s still ample opportunity to grow revenue from customer expansion—nurturing your existing customer relationships, helping them see the value of growing with your solution, and continuing to build loyalty over time.'
            },
            bodysection: [
                {
                    image: CustomerExpansion1,
                    imageAlign: 'right',
                    h3Content: 'Customer Up-sell and Cross-sell',
                    h5Content: 'Measure your customer usage pattern across your product lines, derive insights, and take automated actions to seize the expansion opportunities'
                },
                {
                    image: CustomerExpansion2,
                    imageAlign: 'left',
                    h3Content: 'Increased Revenue',
                    h5Content: 'Improve your business revenue by significant margin while adding a ton of value to your customers and meeting their needs.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Track Customer Usage',
                        h5Content: 'Easily integrate and track your customer usage data'
                    },
                    {
                        h1Content: 'Triggered Alerts',
                        h5Content: 'Take action to address the identified cross-sell and up-sell opportunities'
                    },
                    {
                        h1Content: 'Predictive Analytics',
                        h5Content: 'Derive valuable insights from patterns in usage data'
                    },
                    {
                        h1Content: 'Best Practice Playbooks',
                        h5Content: 'Consistently execute at scale'
                    },
                    {
                        h1Content: 'External Process Automation',
                        h5Content: 'Automate the actions in 3rd party software systems'
                    },
                ],
            },
            contentbox: {
                h3Content: 'Customer Expansion',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    renewalmanagement: [
        {
            headersection: {
                image: RenewalManagement,
                h3Content: 'Renewal Management',
                h6Content: [{h6:'Optimize your Revenue and Forecasts Effectively'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Your membership renewal strategy is a critical component of revenue — and when it’s neglected, your company risks undermining your financial health and overall ability to serve team members for the long term.'
            },
            bodysection: [
                {
                    image: RenewalManagement1,
                    imageAlign: 'right',
                    h3Content: 'Manage Renewals',
                    h5Content: 'Centralize all renewal reports and proactively take actions To executive effectively on all upcoming and overdue renewals.'
                },
                {
                    image: RenewalManagement2,
                    imageAlign: 'left',
                    h3Content: 'Centralized Renewal Process',
                    h5Content: 'Execute and scale your Renewal process across your team.'
                },
                {
                    image: RenewalManagement3,
                    imageAlign: 'right',
                    h3Content: 'Renewal Forecast',
                    h5Content: 'Advanced reporting and Data-driven analytics provides you renewal likelihood.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Data Integration',
                        h5Content: 'Easily integrate your customer data'
                    },
                    {
                        h1Content: 'Playbooks',
                        h5Content: 'Consistently execute at scale'
                    },
                    {
                        h1Content: 'Triggered Alerts',
                        h5Content: 'Take action to address risks'
                    },
                    {
                        h1Content: 'Digital Workflows',
                        h5Content: 'Automate the repeating aspect of the customer success process'
                    },
                    {
                        h1Content: 'Executive Dashboards',
                        h5Content: 'Track and Forecast your most important revenue reports and indicators'
                    },
                ],
            },
            contentbox: {
                h3Content: 'Manage Renewals',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    riskmanagement: [
        {
            headersection: {
                image: RiskManagement,
                h3Content: 'Risk Management',
                h6Content: [{h6:'Manage your Customers Effectively'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Identify and Retain At-Risk Customers – And since churn is a customer lifecycle issue, not just something that happens at the end of the customer lifetime, the seeds of churn are often planted early and they actually start to sprout over the course of the customer lifetime. If you aren’t looking for it, you could miss that your customers are telling you that they’re going to stop paying you at some point very soon. They’re planning their next move and it’s up to you to read the signals and jump into action.'
            },
            bodysection: [
                {
                    image: RiskManagement1,
                    imageAlign: 'right',
                    h3Content: 'Customer Alignment',
                    h5Content: 'Determine your customer’s Business objectives and outcomes are aligned or misaligned by measuring the most important metrics such as usage and MRR.'
                },
                {
                    image: RiskManagement2,
                    imageAlign: 'left',
                    h3Content: 'Convey Product/Service Value',
                    h5Content: 'Find out customer needs and If they are getting value out of your product/service. Convery Differentiation value to customers.'
                },
                {
                    image: RiskManagement3,
                    imageAlign: 'right',
                    h3Content: 'Customize Customer Journey',
                    h5Content: 'Understand Customer needs and engage with them using tailor-made experiences'
                },
                {
                    image: RiskManagement4,
                    imageAlign: 'left',
                    h3Content: 'Maintain Customer Relationship',
                    h5Content: 'Establish a strong relationship with all users to mitigate the risk of champion loss'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Health Trending',
                        h5Content: 'Easily integrate your customer data'
                    },
                    {
                        h1Content: 'Triggered Alerts',
                        h5Content: 'Take action to address risks'
                    },
                    {
                        h1Content: 'Predictive Analytics',
                        h5Content: 'Derive insights from the captured data'
                    },
                    {
                        h1Content: 'Playbooks',
                        h5Content: 'Consistently execute at scale'
                    },
                    {
                        h1Content: 'External Process Automation',
                        h5Content: 'Automate the actions in 3rd party software systems'
                    },
                ],
            },
            contentbox: {
                h3Content: 'Manage Risks',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    customerhealth: [
        {
            headersection: {
                image: CustomerHealth,
                h3Content: 'Customer Health',
                h6Content: [{h6:'Optimize your Customer Relationships'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Measure what matters most to you and your customers. Empower your teams to make educated decisions to build strong, healthy customer relationships.'
            },
            bodysection: [
                {
                    image: CustomerHealth1,
                    imageAlign: 'right',
                    h3Content: 'Gain Customer Insight',
                    h5Content: 'Know where you stand with your customers, Identify risks and opportunities'
                },
                {
                    image: CustomerHealth2,
                    imageAlign: 'left',
                    h3Content: 'Maximize your Impact',
                    h5Content: 'Take the appropriate actions with the appropriate customers at the appropriate time'
                },
                {
                    image: CustomerHealth3,
                    imageAlign: 'right',
                    h3Content: 'Benchmark your Progress',
                    h5Content: 'Measure the impact of your efforts on customer health over time'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Data Integrations',
                        h5Content: 'Easily integrate your customer data'
                    },
                    {
                        h1Content: 'Health Scoring',
                        h5Content: 'Transform data to customer health insights'
                    },
                    {
                        h1Content: 'Triggered Alerts',
                        h5Content: 'Take action to address risks'
                    },
                    {
                        h1Content: 'Custom Data Attributes',
                        h5Content: 'Capture qualitative assessment'
                    },
                    {
                        h1Content: 'Health Trending',
                        h5Content: 'Derive trends in health scores'
                    },
                    {
                        h1Content: 'Playbooks',
                        h5Content: 'Consistently execute at scale'
                    },
                ],
            },
            contentbox: {
                h3Content: 'Customer Health',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    successplanning: [
        {
            headersection: {
                image: SuccessPlanning,
                h3Content: 'Success Planning',
                h6Content: [{h6:'Optimize High-Value Delivery'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'A true customer success plan provides a clear understanding of “what” and “how” you will deliver value throughout the customer lifecycle. The customer’s expectations drive the plan, so you never lose sight of your target. A Customer Success Plan provides consistency throughout the customer journey so that everyone’s focus remains the same: meeting the customer’s expectations—from an opportunity to onboarding to adoption and delivery.'
            },
            bodysection: [
                {
                    image: SuccessPlanning1,
                    imageAlign: 'right',
                    h3Content: 'Expectation Management',
                    h5Content: 'Centralize Customer communication and Customer expectations to Drive highest value and Customer satisfaction.'
                },
                {
                    image: SuccessPlanning2,
                    imageAlign: 'left',
                    h3Content: 'Establishing Process',
                    h5Content: 'Collaborate with customers to understand customer needs and achieve business goals, Supported by a solid success plan framework.'
                },
                {
                    image: SuccessPlanning3,
                    imageAlign: 'right',
                    h3Content: 'Quantified Measurable Goals',
                    h5Content: 'Define the most important Key indicators and milestones And share it with your customers.'
                },
                {
                    image: SuccessPlanning4,
                    imageAlign: 'right',
                    h3Content: 'Accountability Management',
                    h5Content: 'Define and communicate clear ownership to achieve common goals.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Success Plans',
                        h5Content: 'Tailor-made success plan for high touch customer success'
                    },
                    {
                        h1Content: 'Milestones',
                        h5Content: 'Set custom milestones with deadlines'
                    },
                    {
                        h1Content: 'KPIs',
                        h5Content: 'Measure and track the most important indicators'
                    },
                    {
                        h1Content: 'QBR Templates',
                        h5Content: 'Drive QBR with predefined templates'
                    },
                    {
                        h1Content: 'Advanced Reporting',
                        h5Content: ''
                    },
                ],
            },
            contentbox: {
                h3Content: 'Success Planning',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    productadoption: [
        {
            headersection: {
                image: ProductAdoption,
                h3Content: 'Product Adoption',
                h6Content: [{h6:'Drive Cross-Sell and Up-Sell Opportunities'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Product adoption describes the process of users becoming aware of a product, understanding its value, and beginning to use it. The process is usually broken down into four important stages: awareness, interest, evaluation, and conversion.'
            },
            bodysection: [
                {
                    image: ProductAdoption1,
                    imageAlign: 'right',
                    h3Content: 'Gain Market Position',
                    h5Content: 'Reach a wider customer base and a strong foothold in the market by drive adoption campaign.'
                },
                {
                    image: ProductAdoption2,
                    imageAlign: 'left',
                    h3Content: 'Drive the Revenue Up',
                    h5Content: 'Increase business revenue by Identifying and executing on Cross-sell and up-sell opportunities.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                {
                    h1Content: 'Track Customer Usage',
                    h5Content: 'Easily integrate your customer usage data'
                },
                {
                    h1Content: 'Triggered Alerts',
                    h5Content: 'Take action to identify opportunities'
                },
                {
                    h1Content: 'Predictive Analytics',
                    h5Content: 'Derive insights from the captured usage data'
                },
                {
                    h1Content: 'Best Practice Playbooks',
                    h5Content: 'Consistently execute at scale'
                },
            ],
        },
            contentbox: {
                h3Content: 'Product Adoption',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    customercentricsolution: [
        {
            headersection: {
                image: CustomerCentricSolution,
                h3Content: 'Customer-Centric Solution',
                h6Content: [{h6:'Center your business around your customers'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Provide a positive customer experience before and after the sale in order to drive repeat business, enhance customer loyalty, and improve business growth.'
            },
            bodysection: [
                {
                    image: CustomerCentricSolution1,
                    imageAlign: 'right',
                    h3Content: 'Understand Your Customers',
                    h5Content: 'Derive the insights on, what are the most important things for your customers by having all customer information Left, right, and center.'
                },
                {
                    image: CustomerCentricSolution2,
                    imageAlign: 'left',
                    h3Content: 'Feedback driven Continous Improvement',
                    h5Content: 'Engage with your customers To align and meet their needs.'
                },
                {
                    image: CustomerCentricSolution3,
                    imageAlign: 'right',
                    h3Content: 'Empower the Front Line',
                    h5Content: 'Having full visibility into Customers health and their needs customer-facing team members can take appropriate action at the right time.'
                },
                {
                    image: CustomerCentricSolution4,
                    imageAlign: 'left',
                    h3Content: 'Metrics that Matter',
                    h5Content: 'Define and track the Most important metrics For your customers and their Business goals.'
                },

            ],
            howitworksection: { 
            bgColor: 'bg-Eigengrau',
            textColor: 'white',
            title: 'How it works',
            gridCol: 'grid-cols-2',
            innerContent: [
                {
                    h1Content: 'Free Read-only Users',
                    h5Content: 'Add any number of read-only users'
                },
                {
                    h1Content: 'Customer Health',
                    h5Content: 'Track the health of each customer'
                },
                {
                    h1Content: 'UI Builder',
                    h5Content: 'Build Customized views to capture the most important customer information'
                },
                {
                    h1Content: 'Success Plans',
                    h5Content: 'Tailor-made success plan for high-value customer success'
                },
                
            ],
        },
            contentbox: {
                h3Content: 'Customer Collaboration?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    customercollaboration: [
        {
            headersection: {
                image: CustomerCollobration,
                h3Content: 'Customer Collaboration',
                h6Content: [{h6:'Reach Mutual Success'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'It’s well known that improved customer service contributes to improved brand loyalty—positively impacting future sales and profits. Customer collaboration can increase close rates, shorten sales cycles, lower churn, and increase repeat purchase opportunities.It’s well known that improved customer service contributes to improved brand loyalty—positively impacting future sales and profits. Customer collaboration can increase close rates, shorten sales cycles, lower churn, and increase repeat purchase opportunities.'
            },
            bodysection: [
                {
                    image: CustomerCollobration1,
                    imageAlign: 'right',
                    h3Content: 'Improved Customer Experience',
                    h5Content: 'Tailor-made customer experience provides next-level customer engagement'
                },
                {
                    image: CustomerCollobration2,
                    imageAlign: 'left',
                    h3Content: 'Customer Satisfaction',
                    h5Content: 'High-quality products and services tailored to customer needs results in customer joy'
                },

            ],
            howitworksection: {
            bgColor: 'bg-Eigengrau',
            textColor: 'white',
            title: 'How it works',
            gridCol: 'grid-cols-2',
            innerContent: [
                {
                    h1Content: 'Success Plans',
                    h5Content: 'Tailor-made success plan for high touch customer success'
                },
                {
                    h1Content: 'QBR Templates',
                    h5Content: 'Drive QBR with predefined templates'
                },
                {
                    h1Content: 'Customer Portal',
                    h5Content: 'Create customer portals for your customer and collaborate'
                },
                {
                    h1Content: 'Support Center',
                    h5Content: 'Get all the information and help your need at your fingertips'
                },
                {
                    h1Content: 'Free Read-Only Users',
                },
            ],
        },
            contentbox: {
                h3Content: 'Customer Collaboration',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    stakeholderalignment: [
        {
            headersection: {
                image: StakeHolder,
                h3Content: 'Stakeholder Management',
                h6Content: [{h6:'Optimize Customer Engagement Effectively'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Stakeholder engagement, from the outset, helps build involvement and a sense of continuation to a new future. Allow adequate time and planning to include all relevant parties and to allow them to discuss, understand, and internalize each project milestone or step in the process.'
            },
            bodysection: [
                {
                    image: StakeHolder1,
                    imageAlign: 'right',
                    h3Content: 'Key Contact Management',
                    h5Content: 'Identify and manage your key stakeholders – They could be your sponsors, financial decision-makers, champions, etc.'
                },
                {
                    image: StakeHolder2,
                    imageAlign: 'left',
                    h3Content: 'Customer Enagement',
                    h5Content: 'Get customer Participation in the project, so they feel invested in and committed to the strategy. Craft resonating messages after identifying customer needs.'
                },
                {
                    image: StakeHolder3,
                    imageAlign: 'right',
                    h3Content: 'Anticipating Customer Needs',
                    h5Content: 'Once you understand your Customer needs and concerns, The better you can address them.'
                },
                {
                    image: StakeHolder4,
                    imageAlign: 'left',
                    h3Content: 'Customer Value Alignment',
                    h5Content: 'Keep customers aligned – At every key point, stop, discuss, and align and make sure they continue to be your champions.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                {
                    h1Content: 'Customer 360',
                    h5Content: 'Unified view of all your customer’s important information'
                },
                {
                    h1Content: 'Sponsor Tracking',
                    h5Content: 'Track all the important contacts'
                },
                {
                    h1Content: 'Success Plans',
                    h5Content: 'Tailor-made success plan for high touch customer success'
                },
                {
                    h1Content: 'Calendar/Email Integration',
                    h5Content: 'Integrate your favorite email and calendar tools to communicate with your customers'
                },
                {
                    h1Content: 'Free Read-Only Users',
                },
            ],
        },
            contentbox: {
                h3Content: ' Manage Stakeholders?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    voiceofcustomer: [
        {
            headersection: {
                image: VoiceOfCustomer,
                h3Content: 'Voice of the Customer',
                h6Content: [{h6:'Change the way you Listen to and Understand your Customers'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'You know that business metrics like revenue and churn tell only a part of your customer story. To understand why your customers make the decisions they do, it’s critical to listen and understand their perspective. That Voice of the Customer needs to be heard and acted upon throughout your company.'
            },
            bodysection: [
                {
                    image: VoiceOfCustomer1,
                    imageAlign: 'right',
                    h3Content: 'Make better decisions',
                    h5Content: 'Capture and synthesize customer feedback to identify your key customer needs and opportunities.'
                },
                {
                    image: VoiceOfCustomer2,
                    imageAlign: 'left',
                    h3Content: 'Optimize the Customer Journey',
                    h5Content: 'Easily disseminate your customer learnings through the organization to align and drive customer focused enhancements in your products and services.'
                },
                {
                    image: VoiceOfCustomer3,
                    imageAlign: 'right',
                    h3Content: 'Build Strong Customer Relationships',
                    h5Content: 'Track execution across your teams and close the loop with your customers so they know they’ve been heard and their voices matter.'
                },

            ],
            howitworksection: {
                bgColor: 'bg-Eigengrau',
                textColor: 'white',
                title: 'How it works',
                gridCol: 'grid-cols-2',
                innerContent: [
                    {
                        h1Content: 'Support Center',
                        h5Content: 'Having a holistic view of your customer experience'
                    },
                    {
                        h1Content: 'External Process Automation',
                        h5Content: 'Easily share information across teams and other systems'
                    },
                    {
                        h1Content: 'Custom Data Types',
                        h5Content: 'Capture qualitative and quantitative customer feedback'
                    },
                    {
                        h1Content: 'Advanced Reporting',
                    },
                    {
                        h1Content: 'Unlimited Read-only users',
                    },
                ]
            },
            contentbox: {
                h3Content: ' listen to your Customer?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    aiemailgenerator: [
        {
            headersection: {
                image: AiEmail,
                h3Content: 'AI Email Generator',
                h6Content: [{h6:'Ease up writing emails'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-greenColor',
                gridCol: 'grid-cols-1',
                description: 'Elevate your customer engagement with our AI-driven Email Generator – effortlessly craft personalized messages that resonate, driving meaningful connections'
            },
            bodysection: [
                {
                    image: AiEmail1,
                    imageAlign: 'right',
                    h3Content: 'Refresh and Rewrite',
                    h5Content: 'Transform communication with ease using our AI Email Generator'
                },
                {
                    image: AiEmail2,
                    imageAlign: 'left',
                    h3Content: 'Have the time for polish',
                    h5Content: 'The generator takes care of the research for you, so that you can spend more time polishing your content and making it shine. Spend less time worrying about content and more time engaging with your audience'
                },

            ],
            contentbox: {
                h3Content: ' engance Customer Onboarding?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    dataintegration: [
        {
            headersection: {
                image: DataIntegration,
                h3Content: 'Data Integration',
                h6Content: [{h6:'All your customer data in one place'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Success4 Data Integration platform lets you integrate your favorite software tools of CRM, Support Ticket Management, Project Management, Usage Analytics, Account Management, Survey tools, etc.​',
                description: 'Success4 Data Integration platform enables you to integrate, a wide variety of software tools, databases, S3, and Rest API, with great ease. You can easily create data integration feeds from the admin panel. You can flexibly set the schedule of data feed, which enables you to pull the data in real-time in Success4 and in turn automatically updating all KPIs, reports, and dashboards.'
            },
            bodysection: [
                {
                    image: CRM,
                    imageAlign: 'right',
                    h3Content: 'CRM',
                    h5Content: 'Integrate your favorite CRM software such as Salesforce, HubSpot, Zoho, Pipedrive, Outreach etc. Centralize all your customer’s information and engagements in Success4.'
                },
                {
                    image: ProjectManagement,
                    imageAlign: 'left',
                    h3Content: 'Project Management',
                    h5Content: 'It lets you integrate with your favorite project management software such as Jira, Airtable, Asana, etc.'
                },
                {
                    image: UsageTracking,
                    imageAlign: 'right',
                    h3Content: 'Usage Tracking',
                    h5Content: 'Integrate your favorite usage Tracking software like Google Analytics, Mixpanel, etc. Centralize all your customer’s usage within a product and across various products to drive cross-sell/up-sell campaigns'
                },
                {
                    image: SupportManagement,
                    imageAlign: 'left',
                    h3Content: 'Support Management',
                    h5Content: 'Bring all your support desk information In Success4 from tools such as Zendesk and Freshdesk. Build insightful reports that lets you understand what issues your Customers are facing in real-time.'
                },
                {
                    image: Survey,
                    imageAlign: 'right',
                    h3Content: 'Survey',
                    h5Content: 'Let’s you bring in survey data from your favorite tools such As Survey Monkey, Ask Nicely, etc.'
                },

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [
                    {
                        img: SuccessplanningIcon,
                        h5Content: 'Success Planning'
                    },
                    {
                        img: CustomerCentricIcon,
                        h5Content: 'Customer-Centric Culture'
                    },
                    {
                        img: HighTouchIcon,
                        h5Content: 'High Touch Customer Success'
                    },
                    {
                        img: StakeHolderIcon,
                        h5Content: 'Stakeholder Management'
                    },
                    {
                        img: CustomerCollobrationIcon,
                        h5Content: 'Customer Collaboration',
                    },
                    
                ]
            }
            ,
            contentbox: {
                h3Content: ' engance Customer Onboarding?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],   
    uibuilder: [
        {
            headersection: {
                image: UIBuilder,
                h3Content: 'UI Builder',
                h6Content: [{h6:'Build flexible customized views for your needs'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Create customised dashboards with ease',
                description: 'Success4 UI Builder is the state of the art dashboard builder which lets you build completely custom dashboards using UI Components with great ease.'
            },
            bodysection: [
                {
                    image: UIComponent,
                    imageAlign: 'right',
                    h3Content: 'UI Components',
                    h5Content: 'Lets build KPI, score report, table report, various kinds of graph reports, and embedded custom HTML reports.'
                },
                {
                    image: UIDashboard,
                    imageAlign: 'left',
                    h3Content: 'UI Dashboard',
                    h5Content: 'Build highly flexible UI dashboards using UI components. Allows you to resize and move the components with great ease.'
                },                
                {
                    image: DashboardScope,
                    imageAlign: 'right',
                    h3Content: 'Dashboard Scope',
                    h5Content: 'You can select a specific scope for your dashboards. Let’s you choose Account, Global, and User Scope. The same dashboard adapts to different accounts and user.'
                },
                {
                    image: DashboardRoles,
                    imageAlign: 'left',
                    h3Content: 'Dashboard Roles',
                    h5Content: 'Lets you build roles specific dashboards. Select various roles for your dashboards from Customer Success Manager, CS Leadership, and Executive.'
                },

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-col-1',
                innerContent: [                    
                    {
                        img: CustomerCentricIcon,
                        h5Content: 'Customer Centric Solution'
                    },                   
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with UI Builder?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    triggeredalerts: [
        {
            headersection: {
                image: TriggeredAlerts,
                h3Content: 'Triggered Alerts',
                h6Content: [{h6:'Take the Right Action at the Right Time'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Continuously monitor your customer data with custom rules or predictive data science algorithms. When key patterns are matched, trigger the appropriate actions from high tech best practice engagements to tech touch automation.',
                description: 'You can easily create Triggers and alerts in the admin panel. It lets you create custom rules and setup appropriate automated actions. You can tack change in KPIs and Health Scores by setting up change triggers.'
            },
            bodysection: [
                {
                    image: Rules,
                    imageAlign: 'right',
                    h3Content: 'Rules',
                    h5Content: 'Create custom rules to track KPIs, Health Score, MRR, etc.'
                },
                {
                    image: Alerts,
                    imageAlign: 'left',
                    h3Content: 'Alerts',
                    h5Content: 'Raise important alerts through EPA integration and notify your team When a rule is triggered.'
                },                
                {
                    image: PlaybookAssignment,
                    imageAlign: 'right',
                    h3Content: 'Automated Playbook Assignment',
                    h5Content: 'Assign playbooks to accounts automatically when a rule is triggered.'
                },
                {
                    image: ScheduleTrigger,
                    imageAlign: 'left',
                    h3Content: 'Scheduled Triggers',
                    h5Content: 'Easily schedule your rule triggers at fixed times or intervals.'
                },

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [
                    
                    {
                        img: ProductAdoptionIcon,
                        h5Content: 'Product Adoption'
                    },
                    {
                        img: PreventChurnIcon,
                        h5Content: 'Prevent Churn'
                    },
                    {
                        img: TechTouchIcon,
                        h5Content: 'Tech Touch Customer Success'
                    },
                    {
                        img: customerHealthIcon,
                        h5Content: 'Customer Health'
                    },
                    {
                        img: RenewalManagementIcon,
                        h5Content: 'Renewal Management'
                    },
                    {
                        img: RiskManagementIcon,
                        h5Content: 'Risk Management'
                    },
                    {
                        img: CustomerExpansionIcon,
                        h5Content: 'Customer Expansion'
                    },                                    
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with Triggered Alerts?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    externalprocessautomation: [
        {
            headersection: {
                image: EPA,
                h3Content: 'External Process Automation',
                h6Content: [{h6:'Automate Customer Success'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Centrally automate your customer success processes across your favorite software tools including CRM, Email/Calendar, Marketing Automation, Customer service, etc. Build workflows inside Success4 that orchestrate across your external systems',
                description: 'You can easily create new External Process Automations from the admin panel. You can also integrate the External Process Automations with Playbook actions and automate the actions to enable tech touch customer success.'
            },
            bodysection: [
                {
                    image: CRM,
                    imageAlign: 'right',
                    h3Content: 'CRM',
                    h5Content: 'Integrate your favorite CRM software such as Salesforce, HubSpot, Zoho, Pipedrive, Outreach, etc. Send Information from Success4 to your CRM automatically.'
                },
                {
                    image: Email,
                    imageAlign: 'left',
                    h3Content: 'Email/Calendar',
                    h5Content: 'Integrate your favorite email/calendar service to automatically send emails through EPA.'
                },                
                {
                    image: PlaybookCustomIntegration,
                    imageAlign: 'right',
                    h3Content: 'Playbook Action Integration',
                    h5Content: 'Integrate your EPAs with your Playbook actions to enable Tech touch customer success.'
                },
                {
                    image: RestApi,
                    imageAlign: 'left',
                    h3Content: 'Custom Rest API',
                    h5Content: 'Create custom EPAs by integrating your favorite services using REST API EPA integration.'
                },

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [
                    
                    {
                        img: VoiceCustomerIcon,
                        h5Content: 'Voice of the Customer'
                    },
                    {
                        img: RiskManagementIcon,
                        h5Content: 'Risk Management'
                    },
                    {
                        img: CustomerExpansionIcon,
                        h5Content: 'Customer Expansion'
                    },                                                     
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'External Process Automation',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    playbook: [
        {
            headersection: {
                image: Playbook,
                h3Content: 'Playbooks',
                h6Content: [{h6:'Standardize the best series of actions'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Success4 Playbook lets you create a standard set of actions, for your customers, throughout your organization',
                description: 'Manage Customer On-boarding, Renewal, QBR, Cross-sell/Up-Sell, Customer health, etc. You can create playbooks very easily from the admin console and start using them right away. You can also integrate your favorite software tools with playbook actions to perform actions automatically.'
            },
            bodysection: [
                {
                    image: Action,
                    imageAlign: 'right',
                    h3Content: 'Actions',
                    h5Content: 'Take the most important series of actions for tech/low touch customers. You can assign playbooks to accounts and customize the actions for each customer for your needs. You also have access to best practice Playbooks and actions to start with.'
                },
                {
                    image: AssetsNotes,
                    imageAlign: 'left',
                    h3Content: 'Assets/Notes',
                    h5Content: 'Manage your important documents and notes for each action.'
                },                
                {
                    image: ExternalProcessAutomation,
                    imageAlign: 'right',
                    h3Content: 'External Process Automation',
                    h5Content: 'Integrate your favorite software tools with playbook actions to automate the most important tasks right inside from Success4.'
                },
                {
                    image: ActionTimeline,
                    imageAlign: 'left',
                    h3Content: 'Action Timeline',
                    h5Content: 'Visualize all your actions on Gantt chart timeline. It shows you the due dates of all actions and lets you effectively prioritize the actions.'
                },

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [
                    
                    {
                        img: CustomerOnboardingIcon,
                        h5Content: 'Customer Onboarding'
                    },
                    {
                        img: HighTouchIcon,
                        h5Content: 'High Touch Customer Success'
                    },
                    {
                        img: RenewalManagementIcon,
                        h5Content: 'Renewal Management'
                    }, 
                    {
                        img: RiskManagementIcon,
                        h5Content: 'Risk Management'
                    },
                    {
                        img: customerHealthIcon,
                        h5Content: 'Customer Health'
                    },                                                     
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how playbooks fit within your workflow?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    successplan: [
        {
            headersection: {
                image: SuccessPlan,
                h3Content: 'Success Plan',
                h6Content: [{h6:'Achieve Mutual Success with your High-Value customers'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Success4 Success Plan lets you create objectives with KPI and milestones, to provide value to your high touch customers and achieve mutual Success.',
                description: 'Success Plans enables you to manage high touch customer activities with great ease. You can easily create high touch account-specific Success Plans and objectives which are relevant for your business and your customer’s success. It lets you easily define the series of actions that enable you to achieve success based on concretely defined objectives.'
            },
            bodysection: [
                {
                    image: Objectives,
                    imageAlign: 'right',
                    h3Content: 'Objectives',
                    h5Content: 'Define the most important objectives for your business and your customers success, using KPIs and custom milestones with target dates to quantify success, and track the progress automatically.'
                },
                {
                    image: SuccessPlanAction,
                    imageAlign: 'left',
                    h3Content: 'Action',
                    h5Content: 'Take the most important series of actions to provide value to your high touch customers and achieve combined success based on concretely defined objectives.'
                },                
                {
                    image: ExternalSuccessplan,
                    imageAlign: 'right',
                    h3Content: 'External Process Automation',
                    h5Content: 'Integrate your favorite software tools with Success plan actions to automate the most important tasks right inside from Success4.'
                },
                {
                    image: AssetsSuccessPlan,
                    imageAlign: 'left',
                    h3Content: 'Assets/Notes',
                    h5Content: 'Manage your important documents and notes for each Success plan action.'
                },

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [
                    {
                        img: SuccessplanningIcon,
                        h5Content: 'Success Planning'
                    },
                    {
                        img: CustomerCentricIcon,
                        h5Content: 'Customer-Centric Culture'
                    },
                    {
                        img: HighTouchIcon,
                        h5Content: 'High Touch Customer Success'
                    },
                    {
                        img:StakeHolderIcon,
                        h5Content: 'Stakeholder Management'
                    },
                    {
                        img: CustomerCollobrationIcon,
                        h5Content: 'Customer Collaboration',
                    },
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with Success Plans?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],    
    customdataattribute: [
        {
            headersection: {
                image: CustomDataAttribute,
                h3Content: 'Custom Data Attribute',
                h6Content: [{h6:'Extend Your Customer Data with Direct CSM Input'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Success4 Custom Data Attribute lets you capture custom information from your CSMs with great ease.',
                description: 'You can easily create new Custom Data Attributes from the admin panel. You can choose and configure various input fields to select from. It lets you easily capture Customer Sentiment score and other information which is important for your CSMs and Customer Success.'
            },
            bodysection: [
                {
                    image: CaptureQualitative,
                    imageAlign: 'right',
                    h3Content: 'Capture Qualitative Customer Data',
                    h5Content: 'Easily capture your CSMs assessment of Important customer attributes such as Customer Sentiment, Customer Risk, etc. for all your accounts.'
                },
                {
                    image: CustomForm,
                    imageAlign: 'left',
                    h3Content: 'Custom Forms and Fields/Notes',
                    h5Content: 'Structure CSM provided customer data. Create custom forms, choose custom fields such as radio buttons, Textarea, Calendar, checkbox, Select options, etc.'
                },                
                {
                    image: ReadWritePermission,
                    imageAlign: 'right',
                    h3Content: 'Read/Write Permission',
                    h5Content: 'Assign read and write permissions to primary CSM and extended team. Easily manage the access to custom data attributes.'
                },                
            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [                    
                    {
                        img: Customer360Icon,
                        h5Content: 'Customer 360'
                    },
                    {
                        img: VoiceCustomerIcon,
                        h5Content: 'Voice of Customer'
                    },
                    {
                        img: customerHealthIcon,
                        h5Content: 'Customer Health'
                    },                                                                       
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with Custom Data Attributes?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    calendaremailintegration: [
        {
            headersection: {
                image: EmailIntegration,
                h3Content: 'Calendar/Email Integration',
                h6Content:[{h6:'All your customer emails and events in one place'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Success4 Calendar/Email Integration lets you integrate Gmail or Office-365 with great ease',
                description: 'You can easily Integrate your favorite email/calendar platform with the Success4 very easily from admin by connecting your Google or Office-365 account. Manage all your customer’s emails and calendar events from one place.'
            },
            bodysection: [
                {
                    image: Google,
                    imageAlign: 'right',
                    h3Content: 'Google',
                    h5Content: 'Easily integrate Google And manage all your Communication with customers And events from Customer Panel.'
                },
                {
                    image: Office,
                    imageAlign: 'left',
                    h3Content: 'Office-365',
                    h5Content: 'Easily integrate Office-365 And manage all your Communication with customers And events from Customer Panel.'
                },        

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-1',
                innerContent: [
                    
                    {
                        img: StakeHolderIcon,
                        h5Content: 'Stakeholder Management'
                    },                                                                                         
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with Calendar/Email Integration?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    kpis: [
        {
            headersection: {
                image: KeyPerformance,
                h3Content: 'Key Perfoermance Indicators',
                h6Content:[{h6:'Measure the metrics that are most important for Success'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Success4 KPI component lets you create a wide variety of highly flexible KPI metrics',
                description: 'Track your MRR, Usage, progress, account health distribution, NPS, Health Score, etc. You can create a KPI very easily using the KPI UI component in the admin panel. Select the KPI type which is most suitable for your needs. KPI lets you focus on the most important metrics for your success and take appropriate actions at the right time.'
            },
            bodysection: [
                {
                    image: Trending,
                    imageAlign: 'right',
                    h3Content: 'Trending',
                    h5Content: 'Trend KPI lets you see the the trend in the most important metrics like MRR, usage, Health score, etc. for configurable time duration.'
                },
                {
                    image: ProgressBar,
                    imageAlign: 'left',
                    h3Content: 'Progress Bar',
                    h5Content: 'Track and meet your most important targets like target seats, revenue, feature usage, etc. with progress KPI.'
                },   
                {
                    image: Distribution,
                    imageAlign: 'right',
                    h3Content: 'Distribution',
                    h5Content: 'Track the distribution of Health scores, NPS scores, Customer Sentiment scores of all your customers and classify them in bad, average, good, and very good.'
                },
                {
                    image: KPIScope,
                    imageAlign: 'left',
                    h3Content: 'KPI Scopes',
                    h5Content: 'You can select the Account, User, or Global scope for your KPI. The same KPI adapts the information for different users and accounts automatically.'
                },      

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-2',
                innerContent: [                   
                   
                    {
                        img: Customer360Icon,
                        h5Content: 'Customer 360'
                    }, 
                    {
                        img: SuccessplanningIcon,
                        h5Content: 'Success Planning'
                    },                                                                                         
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with KPIs?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    sponsortracking: [
        {
            headersection: {
                image: SponsorTracking,
                h3Content: 'Sponsor Tracking',
                h6Content: [{h6:'Manage All Your Key Relationships'}],
                button: true,
                horizontalLine: true,
                horizontalLineColor: 'border-milkBlue',
                gridCol: 'grid-cols-2',
                h3: 'Sponsor Tracking and Contact management system lets you track all your sponsors and important contact for all your accounts',
                description: 'You can easily add new sponsors and other contacts in the account details contact panel. You can also add other important information such as Email, Title, Primary & Secondary Contact information, and Global tags. It also allows you to create an organization chart by defining the company hierarchy.'
            },
            bodysection: [
                {
                    image: SponsorList,
                    imageAlign: 'right',
                    h3Content: 'Sponsor List',
                    h5Content: 'Easily track all your sponsors. Manage all account contacts from one place.'
                },
                {
                    image: ContactNotes,
                    imageAlign: 'left',
                    h3Content: 'Contact Notes',
                    h5Content: 'Add important notes and details about sponsors and other contacts In all accounts.'
                },   
                {
                    image: GlobalTag,
                    imageAlign: 'right',
                    h3Content: 'Global Tags',
                    h5Content: 'Assign global tags to your contacts and easily manage the categories of contacts. Allows you to search specific contacts.'
                },
                {
                    image: OrganizationChart,
                    imageAlign: 'left',
                    h3Content: 'Organization Chart',
                    h5Content: 'Define organization hierarchy by assigning child and parent contacts for each contact. Visualize the organization chart.'
                },      

            ],
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Solutions',
                gridCol: 'grid-cols-3',
                innerContent: [                   
                   
                    {
                        img: StakeHolderIcon,
                        h5Content: 'Stakeholders Management'
                    }, 
                    {
                        img: HighTouchIcon,
                        h5Content: 'High Touch Customer Success'
                    },  
                    {
                        img: RenewalManagementIcon,
                        h5Content: 'Renewal Management'
                    },                                                                                        
                                    
                ]
            }
            ,
            contentbox: {
                h3Content: 'Want to know how you can get started with Sponsor Tracking?',
                image: LightBrown,
                bgColor: 'bg-mediumSkyBlue'
            }
        }
    ],
    customersuccessmanager: [
        {
            headersection: {
                image: CustomerSuccessManagerPersonas,
                h3Content: 'Customer Success Manager',
                h6Content: [{h6:'A better way to do Customer Success.'},{h6:'Define your Customer Success process’s exact expected behavior and Success4 makes sure that it never deviates'}],
                button: true,
                horizontalLine: false,                               
            },
            bodysection: [
                {
                    image: PlaybookPersonas,
                    imageAlign: 'right',
                    h3Content: 'Playbook Guidance and Health Score',
                    h5Content: 'Account playbooks provide a repeatable, trackable, and systematic way to customer success. Use best practices Playbook actions or define your custom Ones. Track the health of each customer And take appropriate actions'
                },
                {
                    image: EmailPersonas,
                    imageAlign: 'left',
                    h3Content: 'Email and Calendar Integrations',
                    h5Content: 'Email and Calendar from your Gsuite and Office365 accounts can be easily configured and relevant emails and calendar events for specific Accounts can be filtered and made visible in the Account 360 screens.'
                },   
                {
                    image: ExternalProcessAutomationPersonas,
                    imageAlign: 'right',
                    h3Content: 'External Process Automation',
                    h5Content: 'Set up external triggers including alerts via calls, SMS, and other external systems. You can also connect to other applications like Salesforce, Zendesk, Wootric, Pardot, ServiceNow to create tickets, cases, leads, etc.'
                },                   

            ], 
            contentbox: {
                h3Content: ' understand your customers better?',
                bgColor: 'bg-lightsalmonpink',
                image: LightBrown
            },        
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Related Posts',
                gridCol: 'grid-cols-3',
                innerContent: [                   
                   
                    {
                        img: GrowBusiness,
                        h5Content: 'Grow Your Business by Growing Customer Numbers',
                        h6Content: 'February 17, 2021'
                    }, 
                    {
                        img: QuantifyCs,
                        h5Content: 'How to Quantify Customer Success',
                        h6Content:'December 2, 2020'
                    },  
                    {
                        img: OwnershipCs,
                        h5Content: 'Who should own Customer Success',
                        h6Content: 'September 10, 2020'
                    },                                                                                        
                                    
                ]
            },
            
        }
    ],
    executives: [
        {
            headersection: {
                image: ExecutiveCXO,
                h3Content: 'Executives – CXO & VP',
                h6Content: [{h6:'Change the way your organization approaches Customer Success.'},{h6:'Empower your entire organization to engage with customers proactively.'}],
                button: true,
                horizontalLine: false,                
            },
            bodysection: [
                {
                    image: CrossFunctionalTeam,
                    imageAlign: 'right',
                    h3Content: 'Cross-functional teams working as one',
                    h5Content: 'With unlimited read-only users, Success4 enables cross-functional teams to collaborate easily and share information, leading to faster customer success lifecycle.'
                },
                {
                    image: UIBuilderPersonas,
                    imageAlign: 'left',
                    h3Content: 'Easy to use UI Builder and BI Integrations',
                    h5Content: 'Success4 enables you to create new pages, reports, KPIs and quickly add it to the CSM side using a state of the art, drag-and-drop UI builder.'
                },   
                {
                    image: ExternalProcessAutomationPersonas,
                    imageAlign: 'right',
                    h3Content: 'External Process Automation',
                    h5Content: 'Set up external triggers including alerts via calls, SMS, and other external systems. You can also connect to other applications like Salesforce, Zendesk, Wootric, Pardot, ServiceNow to create tickets, cases, leads, etc.'
                },
                {
                    image: SecurityComplaince,
                    imageAlign: 'left',
                    h3Content: 'Security And Compliance',
                    h5Content: 'Success4 is a multi-tenant platform, where customer data is logically separated by a unique identifier and access control is strongly enforced at the application level. Choose cloud or on-prem deployment model in alignment with your security and compliance practices.'
                },      

            ],           
            contentbox: {
                h3Content: ' understand your customers better?',
                bgColor: 'bg-lightsalmonpink',
                image: LightBrown
            },
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Related Posts',
                gridCol: 'grid-cols-3',
                innerContent: [                   
                   
                    {
                        img: GrowBusiness,
                        h5Content: 'Grow Your Business by Growing Customer Numbers',
                        h6Content: 'February 17, 2021'
                    }, 
                    {
                        img: QuantifyCs,
                        h5Content: 'How to Quantify Customer Success',
                        h6Content:'December 2, 2020'
                    },  
                    {
                        img: OwnershipCs,
                        h5Content: 'Who should own Customer Success',
                        h6Content: 'September 10, 2020'
                    },                                                                                        
                                    
                ]
            },
            
        }
    ],
    customersuccessoperation: [
        {
            headersection: {
                image: CustomerOperation,
                h3Content: 'Customer Success Operations',
                h6Content: [{h6:'Change the way your organization approaches Customer Success.'},{h6:'Empower your entire organization to engage with customers proactively.'}],
                button: true,
                horizontalLine: false,                
            },
            bodysection: [
                {
                    image: TwoWayCRM,
                    imageAlign: 'right',
                    h3Content: 'Two Way Sync with Your CRM',
                    h5Content: 'Integrate your CRM with Success4 within minutes, with two-way sync, And ensure your CRM and Success4 is up to date with customer information.'
                },
                {
                    image: RoleBasedAccess,
                    imageAlign: 'left',
                    h3Content: 'Role-Based Access Control',
                    h5Content: 'Easily migrate your AD groups, and create Admin, CSM, Read-Only, and Customer roles and maintain the platform access accordingly.'
                },   
                {
                    image: PromoteService,
                    imageAlign: 'right',
                    h3Content: 'Promote Self Service',
                    h5Content: 'With Success4 users can create Custom data types and sync it back to CRM, ticketing, and marketing campaign systems without Needing any additional intervention By operational support teams.'
                },
                {
                    image: SeamlessDataIntegrity,
                    imageAlign: 'left',
                    h3Content: 'Seamless Integration with Data Integrity',
                    h5Content: 'Integrate quickly and easily with CRM, ticketing, survey, product analytics, and campaign systems while ensuring data integrity, security, and GDPR compliance in the process'
                },      

            ],
            contentbox: {
                h3Content: 'customers better',
                bgColor: 'bg-lightsalmonpink',
                image: LightBrown
            },
            howitworksection:
            {
                bgColor: 'bg-ghostWhite',
                textColor: 'black',
                title: 'Related Posts',
                gridCol: 'grid-cols-3',
                innerContent: [                   
                   
                    {
                        img: GrowBusiness,
                        h5Content: 'Grow Your Business by Growing Customer Numbers',
                        h6Content: 'February 17, 2021'
                    }, 
                    {
                        img: QuantifyCs,
                        h5Content: 'How to Quantify Customer Success',
                        h6Content:'December 2, 2020'
                    },  
                    {
                        img: OwnershipCs,
                        h5Content: 'Who should own Customer Success',
                        h6Content: 'September 10, 2020'
                    },                                                                                        
                                    
                ]
            },
        }
    ]
}