// Accordion.js
import React, { useState } from 'react';
import { GrDown, GrUp } from 'react-icons/gr';

const Accordion = ({ tablerow, children,onApplyNow }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [hide,setHide]=useState(false)
console.log(tablerow.designation)
    return (
        <>
        {!hide&&<div className="border-b border-gray-200 bg-white  shadow-custom">
            <div
                
                className=" flex md:block w-full px-4 py-2 text-left text-sm sm:text-md font-medium text-black bg-white border-b border-gray-200 focus:outline-none "
            >
                <div  className='flex flex-col  md:flex-row md:justify-between md:items-center w-full'>
                        <div>{tablerow.designation}</div>
                        <div>{tablerow.yoe}</div>
                        <div className='flex md:gap-3 gap-1 sm:gap-2'>
                       View on:{tablerow.viewon.map((viewon,key)=><div className='text-[#2955F0] '>
                            <span>{viewon}</span>
                       </div>) }
                       </div>
                       <div className='flex gap-8 items-center'>
                       <button className='bg-black p-2 text-white rounded-md' onClick={()=>{setHide(true); onApplyNow();}}>Apply Now</button>
                       <span className='cursor-pointer md:block hidden'>{isOpen ?<GrUp onClick={() => setIsOpen(!isOpen)}/> : <GrDown onClick={() => setIsOpen(!isOpen)}/>}</span>
                       </div>
                </div>  
                <span className='cursor-pointer block md:hidden'>{isOpen ?<GrUp onClick={() => setIsOpen(!isOpen)}/> : <GrDown onClick={() => setIsOpen(!isOpen)}/>}</span>
            </div>
            {isOpen && (
                <div className="px-4 pt-2 pb-4 text-black ">
                    {children}
                </div>
            )}
        </div>}
      
        </>

    );
};

export default Accordion;
