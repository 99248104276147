import React from 'react'
import QuantifyImg from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import DataControl from '../../../../assets/data_control.svg'
import RoiCs from '../../../../assets/roi_cs.svg'
import CustomerStrategy from '../../../../assets/strategies_2019.svg'
import GrowBusiness from '../../../../assets/GrowBusines.png'
import { Link } from 'react-router-dom'
export default function Blog() {
    const BlogData = [
        {name:'How to Quantify Customer Success', img:QuantifyImg, description :'Customer success is something we all strive for. However, we tend to focus most on the metrics that directly impact', createdBy:'PRASHANT / DECEMBER 2, 2020'},
        {name:'Who should own Customer Success', img:Ownership, description :'One of the challenges of being in Customer Success is it often gets lost inside an organization in terms of', createdBy:'BALAJI / SEPTEMBER 10, 2020'},
        {name:'Controlling Your Customer Success Data in the Cloud', img:DataControl, description :'Customer success is vital to your business and so is the integrity and security of your data. With the current', createdBy:'VIVEK / JULY 20, 2020'},
        {name:'Getting Full ROI from your Customer Success Investment', img:RoiCs, description :'Customer success is something we all strive for. However, we tend to focus most on the metrics that directly impact', createdBy:'PRASHANT / DECEMBER 2, 2020'},
        {name:'4 Effective Customer Success Strategies for 2019', img:CustomerStrategy, description :'Customer success is something we all strive for. However, we tend to focus most on the metrics that directly impact', createdBy:'PRASHANT / DECEMBER 2, 2020'},
    ]
    return (
        <div className='w-full'>
            <section className='md:px-[10%] mr-auto ml-auto relative flex px-[2%] '>
                <div className='pt-[40px]  w-full '>

                    <div className='flex flex-col md:items-start items-center '>
                        <h3 className='md:text-3xl text-center md:text-left lg:text-4xl text-2xl font-semibold '>Blog</h3>
                        <h6 className='text-[20px] mt-[15px] mb-[28px] text-textGrayColor'>What’s new in Customer Success?</h6>
                    </div>
                </div>
            </section>
            <div className='bg-ghostWhite  relative mb-[5vh]'>
                <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%] py-[2%]'>
                    <div className='pt-[40px]'>
                        <div className='flex items-center justify-center '>
                            <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
                            <Link to='/how-to-quantify-customer-success'>
                                    <img src={QuantifyImg} />
                                    <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>How to Quantify Customer Success</h2>
                                    <p className='text-grayColor'>{'December 2, 2020'}</p>
                                </Link>
                                <Link to='/who-should-own-customer-success'>
                                    <img src={Ownership} />
                                    <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Who should own Customer Success</h2>
                                    <p className='text-grayColor'>{'September 10, 2020'}</p>
                                </Link>
                                <Link to='/controlling-your-customer-success-data-in-the-cloud'>
                                    <img src={DataControl} />
                                    <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Controlling Your Customer Success Data in the Cloud</h2>
                                    <p className='text-grayColor'>{'September 10, 2020'}</p>
                                </Link>
                                <Link to='/getting-full-roi-from-your-customer-success-investment-3'>
                                    <img src={RoiCs} />
                                    <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Getting Full ROI from your Customer Success Investment</h2>
                                    <p className='text-grayColor'>{'September 10, 2020'}</p>
                                </Link>
                                <Link to='/4-effective-customer-success-strategies-for-2019'>
                                    <img src={CustomerStrategy} />
                                    <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>4 Effective Customer Success Strategies for 2019Related Po</h2>
                                    <p className='text-grayColor'>{'September 10, 2020'}</p>
                                </Link>
                                <Link to='/grow-your-business-by-growing-customer-numbers'>
                                    <img src={GrowBusiness} />
                                    <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Grow Your Business By Growing Customer Numbers</h2>
                                    <p className='text-grayColor'>{'September 10, 2020'}</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


