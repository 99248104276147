import React, { useState } from 'react'
import Accordion from '../../CommonFunction/Accordion/Accordian';
import {GrUpload} from 'react-icons/gr'
export default function Career() {
  

     const accordionsData = [
        // {
        //     tablerow:{
        //         designation:"sr software engineer",
        //         yoe:"5 years",
        //         viewon: ['Linkedln', 'Angel list']
        //     },
        //     content: (
        //         <div className='flex flex-col gap-5'>
        //         <div>
        //         <div className='font-bold py-5'>Job Requirements</div>
        //         <ul className="list-disc ml-5">
        //             <li>You have a deep understanding of how software is built and deployed</li>
        //             <li>You can demonstrate significant impact that your work has had on a product and/or the team</li>
        //             <li>Passionate and motivated about creating great customer experiences that solve real problems</li>
        //             <li>Ability to communicate effectively with product and design</li>
        //             <li>Similarly, you have very strong verbal and written English communication skills</li>
        //         </ul>
        //         </div>
        //         <div>
        //         <div className='font-bold py-5'>Technical Skill</div>
        //         <ul className="list-disc ml-5">
        //             <li>You have a deep understanding of how software is built and deployed</li>
        //             <li>You can demonstrate significant impact that your work has had on a product and/or the team</li>
        //             <li>Passionate and motivated about creating great customer experiences that solve real problems</li>
        //             <li>Ability to communicate effectively with product and design</li>
        //             <li>Similarly, you have very strong verbal and written English communication skills</li>
        //         </ul>
        //         </div>
        //         </div>
        //     ),
        // },
        // {
        //     title: "Job Requirements",
        //     tablerow:{
        //         designation:"sr software engineer",
        //         yoe:"5 years",
        //         viewon: ['Linkedln', 'Angel list']
        //     },
        //     content: (
        //         <ul className="list-disc ml-5">
        //             <li>You have a deep understanding of how software is built and deployed</li>
        //             <li>You can demonstrate significant impact that your work has had on a product and/or the team</li>
        //             <li>Passionate and motivated about creating great customer experiences that solve real problems</li>
        //             <li>Ability to communicate effectively with product and design</li>
        //             <li>Similarly, you have very strong verbal and written English communication skills</li>
        //         </ul>
        //     ),
        // },
       
    ];
    
    const [showForm, setShowForm] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleApplyNow = () => {
        setShowForm(true);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (selectedFile) {
            // Handle file upload logic here
            console.log('File selected:', selectedFile);
        } else {
            console.log('No file selected');
        }
    };

    return (
        <>
        {!showForm&&<div className=" mx-auto mt-10 flex flex-col gap-10 w-full md:px-[10%] px-[2%]">
            <div className='py-20 text-center flex flex-col gap-2'>
                <h1 className='text-6xl bold'>Find your next opportunity</h1>
                <p className='text-xl'>We're always on the lookout for amazing people.</p>
            </div>
           
        </div>}
         <div className={`bg-[#F2F4FA] flex flex-col gap-10 md:px-[10%] px-[2%] ${showForm?'py-2':'py-20'}`}>
         {accordionsData && accordionsData.length > 0 ?
         (!showForm&&accordionsData.map((accordion, index) => (
             <Accordion key={index} tablerow={accordion.tablerow}  onApplyNow={handleApplyNow}>
                 {accordion.content}
             </Accordion>
         ))):(
            <h1 className='text-[#1D42BC] text-center text-5xl py-32'>No openings at the moment :( </h1>
         )}
         
          {showForm && (
             <>
             <div className='flex gap-5 text-[#2955F0] py-3 px-2'>
                <span className='cursor-pointer' onClick={()=>setShowForm(false)}>{'>'}</span>
                <div>Career</div>
            </div>
             </>
            )}
         </div>
         {showForm && ( <div className='w-full items-center justify-center flex my-5 '>
                <div className='border border-[#F2F4FA] shadow-custom w-[40%] p-14 flex gap-4 flex-col rounded-2xl'>
                    <div className='flex flex-col gap-2'>
                        <label className='text-sm'>Which role are you applying for?<span className='pl-1 text-[#FF0000]'>*</span> </label>
                        <select className='border border-[#12253A66] p-3'>
                            <option>sr.software engineet</option>
                        </select>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-sm'>Full Name<span className='pl-1 text-[#FF0000]'>*</span> </label>
                        <input className='border border-[#12253A66] p-3 ' type="text"  placeholder='Enter your fullname' name='fullname'/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-sm'>Email<span className='pl-1 text-[#FF0000]'>*</span> </label>
                        <input className='border border-[#12253A66] p-3' type="text"  placeholder='Enter your email' name='fullname'/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-sm'>Current Company<span className='pl-1 text-[#FF0000]'>*</span> </label>
                        <input className='border border-[#12253A66] p-3' type="text"  placeholder='Enter your current company name' name='fullname'/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-sm'>Linkedln URL </label>
                        <input className='border border-[#12253A66] p-3' type="text"  placeholder='Enter your linkedln profile link' name='fullname'/>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <label className='text-sm'>Portfolio / Personal Website URL </label>
                        <input className='border border-[#12253A66] p-3' type="text"  placeholder='Enter your Portfolio / Personal Website URL' name='fullname'/>
                    </div>
                    <div className='flex flex-col gap-2'>
                            <label className='text-sm'>Please attach your Resume/CV<span className='pl-1 text-[#FF0000]'>*</span></label>
                            <input
                                type="file"
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                                className="hidden"
                                id="resume-upload"
                            />
                            <label htmlFor="resume-upload" className='border border-[#12253A66] p-3  flex gap-4 items-center justify-center cursor-pointer'>
                                <GrUpload />
                                <h2>Upload</h2>
                            </label>
                            {selectedFile && <span className='text-sm mt-2 items-center justify-center flex'>{selectedFile.name}</span>}
                        </div>
                        <button className='px-20 py-3 rounded-md bg-[#09122E] text-white'>Submit</button>
                   
                </div>
         </div>)}
         </>
    );
 
  
}
