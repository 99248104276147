import React,{useState} from 'react'
import GrowBusiness from '../../../../assets/GrowBusines.png'
import QuantifyImg from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import DataControl from '../../../../assets/data_control.svg'
import RoiCs from '../../../../assets/roi_cs.svg'
import Hand from '../../../../assets/hand.png'
import { Link } from 'react-router-dom'
import RequestDemo from '../../../CommonFunction/PopHover/RequestDemo'

export default function Quantify() {
    const [isPopupVisible, setPopupVisible] = useState(false);
      
    const handleRequestDemoClick = () => {
      setPopupVisible(true);
    };
  
    const handleClosePopup = () => {
      setPopupVisible(false);
    };
    return (
        <div className='w-full' >
            <div className='mb-[5vh]'>
                <div className='mb-[5vh]'>
                    <div className='w-full absolute  '>
                        <img className='w-full xl:h-[calc(100vh-15vh)] object-cover' src={QuantifyImg} />
                    </div>
                    <section className='relative flex flex-col items-center justify-end xl:h-[calc(100vh-15vh)]  bg-black bg-opacity-30  mb  text-white'>
                    <Link to='/blog' className='pb-[2%] text-center'>Back to Blogs</Link>
                        <h1 className='text-4xl font-bold pb-[2%] text-center'>How to Quantify Customer Success</h1>
                        <p className='pb-[5%] text-center'>Prashant / December 2, 2020</p>
                    </section>
                </div>
                <div className='w-full wrap text-[18px] '>
                    <div className='flex flex-col items-center justify-center '>
                        <div className='md:px-[20%] px-[5%]'>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>Customer success is something we all strive for. However, we tend to focus most on the metrics that directly impact the bottom line, and there’s much more to it than that.</div>
                            <div className='wrap  leading-[27px] py-3 text-grayColor'>
                                Sales conversions may be the ultimate endgame, but customer retention, engagement, and reputation are all critical aspects as well. Though these attributes may be more difficult to quantify into hard numbers, they are essential mechanisms in the quest for business growth.
                            </div>
                            <h1 className='text-[30px] font-bold leading-[40px] pt-10'>Quantifying Customer Success: Four Essential Elements</h1>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                You can think of customer success as a footbridge between scale and the status quo. Here are some insights and actionable advice to get you thinking:
                            </div>
                            <div>
                                <h1 className='text-[30px] font-bold leading-[27px] '>1.     Know Your Customer</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    It goes without saying that you should play to your audience, but how well do you really know them? Knowing your customer means that you can give them what they want without a lot of trial and error. This shortens your conversion timelines and helps you get more done in a shorter amount of time.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    How to do it: Develop ideal customer personas to better understand their intent. Identify their pain points and highlight common problems that you can solve for them. This way, you won’t just be pitching them, you will be delivering value and building trust.
                                </div>
                            </div>
                            <div >
                                <h1 className='text-[30px] font-bold leading-[27px]' >2.     Build Engagement</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    You can’t possibly solve problems or build authority with your customers unless you know what they’re saying. Pay attention to their comments and feedback; make sure the conversation goes both ways. Keep in mind, too, that negative feedback is just as powerful as positive feedback. Take what you learn and use it to improve and grow.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    How to do it: Provide your customers with a multitude of ways to engage with you. Solicit feedback, engage directly with them, share what you hear across all your customer touchpoints. Listen first; because you’ll never learn anything if you’re the one doing all the talking.
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >3.     Customer Retention</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    The cost of gaining a new customer can be 500 percent greater than what it costs to keep an existing one. With that in mind, it makes a lot of sense to make sure your current customers are happy. Despite this statistic, many companies put more focus on customer acquisition than retention.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    How to do it: The key to customer retention is to create a great customer experience. Use every channel at your disposal – to ensure you are giving your customers a cohesive and consistent experience.
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >4.     Business Growth</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    If you’ve applied the previous three principles, then business growth should follow. To sustain that growth, you should always be looking for new ways to meet your customer’s needs. This could be through new products, enhanced services, or by making life easier for them in some way.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    How to do it: Once you have established a reputation in your niche, maintain and build it by continuing to improve and innovate. No industry exists in a vacuum. Always be on the leading edge of new insights, technology, and information that your customer base craves and deliver it to them in a way they can understand. This way, you will always be top-of-mind when it comes time to buy.
                                </div>
                            </div>
                            <div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >The Customer Success Equation: How to Measure these Elements’</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    We can all count money, but what about all that other stuff that goes into making the bacon? It’s easy to see the results of customer success, but how do you put it into numbers?
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    Here are the elements and KPIs you should be tracking:
                                </div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >Reach</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    Your total reach relates to the number of prospective customers as well as your current customer base. How effectively are you reaching your target audience? Is your customer getting value from your product or service? Has it solved their problem?
                                </div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >Engagement</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    Engagement is critical to business growth. Knowing how many of your customers are interacting with your product helps you see past the blind spots that may hinder your progress.
                                </div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >Reliability</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    Do you know how reliably your product performs for your customers? This metric, seen through the customer’s lens, is an indispensable gauge of customer success.
                                </div>
                                <h1 className='text-[30px] font-bold leading-[27px]' >Responsiveness</h1>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    How long does it take to meet your customer’s needs? Bottom line: the faster your team or product can help the customer achieve their desired outcome, the happier the customer will be.
                                </div>
                            </div>
                            <h1 className='text-[30px] font-bold leading-[27px]' >How Success4 Can Help You Quantify Customer Success</h1>
                            <div className='leading-[27px] py-3 text-grayColor'>
                                Success4 is a tool designed to help teams better manage their customer success programs. Our platform provides you with a 360˚ view of your customer journey, helping you quickly identify what’s working, and more importantly, what isn’t.
                            </div>
                            <div className='leading-[27px] py-3 text-grayColor'>
                                To learn more or to request a free trial, reach out today.
                            </div>
                            <div className='leading-[27px] py-3 text-grayColor'>
                                Link to 2-minute video
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='flex justify-between mb-[5vh] md:px-[10%] px-[2%]'>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                    <Link to='/who-should-own-customer-success'>
                            <img height='87' width='87' src={Ownership} />
                        </Link>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>PREVIOUS ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Who should own Customer Success</p>
                    </div>
                </div>
                <div className='flex md:flex-row flex-col gap-5'>

                    <div>
                    <Link to='/grow-your-business-by-growing-customer-numbers'>
                            <img height='87' width='87' src={GrowBusiness} />
                        </Link>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>NEXT ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Grow Your Business By Growing Customer Numbers</p>
                    </div>
                </div>
            </div>
            <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%]'>
                <h2 className={`text-black  font-bold text-[24px] text-center`}>Related Posts</h2>
                <div className='pt-[40px]'>
                    <div className='flex items-center justify-center '>
                        <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
                        <Link to='/who-should-own-customer-success'>
                                <img src={Ownership} />
                                <a className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'><h2>Who should own Customer Success</h2></a>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </Link>
                            <Link to='/controlling-your-customer-success-data-in-the-cloud'>
                                <img src={DataControl} />
                                <a className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>
                                    <h2>Controlling Your Customer Success Data in the Cloud</h2></a>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </Link>
                            <Link to='/getting-full-roi-from-your-customer-success-investment-3'>
                                <img src={RoiCs} />
                                <a className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>
                                    <h2>Getting Full ROI from your Customer Success Investment</h2></a>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </Link>

                        </div>
                    </div>
                </div>
            </div>
            <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
                <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
                    <div>
                        <h3 className='lg:text-3xl md:text-xl font-bold pb-[5vh]'>Want to know how we can help you understand your customers better?</h3>
                        <div className='flex md:flex-row flex-col gap-5'>
                            <input
                                className=" md:w-[25vw] border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                                placeholder="Your Email Address"
                            />
                            <button onClick={handleRequestDemoClick} className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                        </div>
                        {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
                    </div>
                    <img src={Hand} />
                </div>
            </section>
        </div>
    )
}
