import React,{useState} from 'react'
import QuantifyImg from '../../../../assets/quantify_cs.svg'
import Ownership from '../../../../assets/ownership_cs.svg'
import DataControl from '../../../../assets/data_control.svg'
import RoiCs from '../../../../assets/roi_cs.svg'
import Hand from '../../../../assets/hand.png'
import { Link } from 'react-router-dom'
import RequestDemo from '../../../CommonFunction/PopHover/RequestDemo'


export default function CustomerSuccess() {
    const [isPopupVisible, setPopupVisible] = useState(false);
      
    const handleRequestDemoClick = () => {
      setPopupVisible(true);
    };
  
    const handleClosePopup = () => {
      setPopupVisible(false);
    };
    return (
        <div className='w-full' >
            <div className='mb-[5vh]'>
                <div className='mb-[5vh]'>
                    <div className='w-full absolute  '>
                        <img className='w-full xl:h-[calc(100vh-15vh)] object-cover' src={Ownership} />
                    </div>
                    <section className='relative flex flex-col items-center justify-end xl:h-[calc(100vh-15vh)]  bg-black bg-opacity-30   text-white'>
                    <Link to='/blog' className='pb-[2%] text-center'>Back to Blogs</Link>
                        <h1 className='text-4xl font-bold pb-[2%] text-center'>Who should own Customer Success</h1>
                        <p className='pb-[5%] text-center'>Balaji / September 10, 2020</p>
                    </section>
                </div>
                <div className='w-full wrap text-[18px] '>
                    <div className='flex flex-col items-center justify-center '>
                        <div className='md:px-[20%] px-[5%]'>
                            <div className=' wrap  leading-[27px] py-3 text-grayColor'>One of the challenges of being in Customer Success is it often gets lost inside an organization in terms of who it should report into. In a recent survey I conducted, I found no dominant area</div>

                            <h1 className='text-[30px] font-bold leading-[40px] pt-10'>Your Customer Success Team Reports Into</h1>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                <table className="min-w-full bg-white border border-gray-300">

                                    <tbody >
                                        <tr>
                                            <td className="py-2 px-4 border-b">CEO or CCO</td>
                                            <td class="py-2 px-4 border-b">23.81%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">VP of Sales or CRO</td>
                                            <td className="py-2 px-4 border-b">33.33%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">VP of Customer Support or Services</td>
                                            <td className="py-2 px-4 border-b">33.33%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">VP of Engineering</td>
                                            <td className="py-2 px-4 border-b">4.76%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">Other</td>
                                            <td className="py-2 px-4 border-b">4.76%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>

                                <div className='leading-[27px] py-3 text-grayColor italic'>
                                    (Survey results August 2019, Customer Success Professionals in the Boston area)
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    One other concern is that Customer Success was more frequently part of a reorganization, moving from one group to another. While the goals and objectives of the Customer Success team may stay the same, the goals and objectives of the larger organization may influence how its mission is carried out. For example, when Customer Success is part of a support organization it is highly unlikely CSM’s compensation will be tied to revenue goals, but when that same team moves into sales and the VP of Sales wants to drive a renewal number, you’re more likely to see comp plans change. While I don’t see a right or wrong side to this, an organization has to build historical evidence to understand what is effective and what is not.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    The other area that is important is responsibility. When a team is involved in an outcome but is not responsible for that outcome it gets difficult to model behavior. Two areas that come to mind are expansion sales and renewals. I see more Customer Success teams owning renewals, but in many cases another team will own the goal. Expansion I tend to see more organizations treat Customer Success like a lead generation engine and still look to sales to own the goal. I am not saying this is wrong, but the challenge is fostering behaviors that drive outcomes when Customer Success does not own that outcome.
                                </div>
                                <div className='leading-[27px] py-3 text-grayColor'>
                                    From the survey I found a majority of Customer Success organizations focused on onboarding and adoption (more services or support based functions) while a lower percentage focused on the more sales-oriented functions of expansion/up-sell and renewal.
                                </div>
                            </div>
                            <div >
                                <h1 className='text-[30px] font-bold leading-[27px]' >Functional Area Customer Success is Involved In</h1>

                            </div>
                            <div className='leading-[27px] wrap text-grayColor py-3'>
                                <table className="min-w-full bg-white border border-gray-300">

                                    <tbody >
                                        <tr>
                                            <td className="py-2 px-4 border-b">On-boarding – helping customers get started </td>
                                            <td class="py-2 px-4 border-b">76.19%%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">Adoption – helping customers achieve business goals   </td>
                                            <td className="py-2 px-4 border-b">90.48%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">Expansion/up-sell – helping customers explore new capabilities</td>
                                            <td className="py-2 px-4 border-b">47.62%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">Renewal – ensure customers see value</td>
                                            <td className="py-2 px-4 border-b">66.67%</td>
                                        </tr>
                                        <tr>
                                            <td className="py-2 px-4 border-b">Other</td>
                                            <td className="py-2 px-4 border-b">14.29%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='leading-[27px] py-3 text-grayColor italic'>
                                (Survey results August 2019, Customer Success Professionals in the Boston area)
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='flex justify-between mb-[5vh] md:px-[10%] px-[2%]'>
                <div className='flex md:flex-row flex-col gap-5'>
                    <div>
                    <Link to='/controlling-your-customer-success-data-in-the-cloud'>
                            <img height='87' width='87' src={DataControl} />
                        </Link>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>PREVIOUS ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Controlling Your Customer Success Data in the Cloud</p>
                    </div>
                </div>
                <div className='flex gap-5 md:flex-row flex-col'>
                    <div >
                    <Link to='/getting-full-roi-from-your-customer-success-investment-3'>
                            <img height='87' width='87' src={RoiCs} />
                        </Link>
                    </div>
                    <div className='w-[10vw]'>
                        <h3 className='text-[12px]'>NEXT ARTICLE</h3>
                        <p className='text-[14px] font-bold text-grayColor'>Getting Full ROI from your Customer Success Investment</p>
                    </div>
                </div>
            </div>
            <div className='w-full bg-ghostWhite mb-[2%] md:px-[10%] px-[2%] py-[2%]'>
                <h2 className={`text-black  font-bold text-[24px] text-center`}>Related Posts</h2>
                <div className='pt-[40px]'>
                    <div className='flex items-center justify-center '>
                        <div className={`md:grid md:grid-cols-3  md:grid-flow-row grid-cols-1 md:leading-[50px] grid gap-y-10 gap-x-5`}>
                        <Link to='/how-to-quantify-customer-success'>
                                <img src={QuantifyImg} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>How to Quantify Customer Success</h2>
                                <p className='text-grayColor'>{'December 2, 2020'}</p>
                            </Link>
                            <Link to='/controlling-your-customer-success-data-in-the-cloud'>
                                <img src={Ownership} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Controlling Your Customer Success Data in the Cloud</h2>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </Link>
                            <Link to='/getting-full-roi-from-your-customer-success-investment-3'>
                                <img src={RoiCs} />
                                <h2 className='text-[18px] md:leading-[30px] text-black md:h-[8vh]'>Getting Full ROI from your Customer Success Investment</h2>
                                <p className='text-grayColor'>{'September 10, 2020'}</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <section className='font-nunito-sans  pb-[5vh] md:px-[10%] px-[2%]'>
                <div className=' bg-yellow  rounded-[2vh] md:flex-row md:items-center md:justify-center md:flex flex-col pt-8 px-8'>
                    <div>
                        <h3 className='lg:text-3xl md:text-xl font-bold pb-[5vh]'>Want to know how we can help you understand your customers better?</h3>
                        <div className='flex md:flex-row flex-col gap-5'>
                            <input
                                className=" md:w-[25vw] border border-solid-gray p-3 border-[1px] rounded-[15px] hover:border-blue-500 focus:border-blue-500 focus:outline-none focus-within:border-blue-500"
                                placeholder="Your Email Address"
                            />
                             <button onClick={handleRequestDemoClick} className=' lg:w-[13vw] sm:w-[20vw]  mt-[2vh] w-[30vw] bg-black text-white h-[6vh] text-sm md:text-md xl:text-xl rounded-[1vh]'>Request Demo</button>
                        </div>
                        {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
                    </div>
                    <img src={Hand} />
                </div>
            </section>
        </div>
    )
}
