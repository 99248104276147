import React, { useLayoutEffect, useState } from 'react';
import { pages } from './metadata';
import ComonPage from './CommonPage';
import { useLocation } from 'react-router-dom';

const PageLayout = (props) => {
    const [metadata, setMetaData] = useState([]);
    useLayoutEffect(() => {
        setMetaData(pages[props.id]);
    }, [props.id]);

    const location = useLocation();
    const { ids } = location.state || {}; 


    return (
        <>
            <div cds-layout='p:lg'>
                <ComonPage metadata={metadata} />
            </div>
        </>
    );
};

export default React.memo(PageLayout);
