import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="mx-auto py-6  flex flex-col text-justify font-nunito-sans md:px-[25%] px-[2%]">
      <h1 className="text-6xl font-bold mb-4 text-center text-[#3E66F2]">Privacy Policy</h1>
      {/* <p className="text-sm text-gray-600">Effective Date: [Insert Date]</p> */}
      
      <section className="mt-8 text-lg">
        {/* <h2 className="text-2xl font-semibold">1. Introduction</h2> */}
        <p className="mt-2">Success4, Inc. ("we," "us," "our") respects your privacy and is committed to protecting it through our compliance with this policy. This Privacy Policy explains our practices regarding the collection, use, and disclosure of your information when you visit our website <a href="https://success4.us" className="text-blue-500">https://success4.us</a> ("Site") or use our services ("Services").</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">What Information We Collect?</h2>
        
        <h3 className="text-2xl font-semibold mt-4">Personal Information</h3>
        <p className="mt-2 ">We collect personal information that identifies you as an individual, including:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>Name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Billing information (including credit card details)</li>
        </ul>
        <p className="mt-2">This information is collected when you register, subscribe, or use our services.</p>
        
        <h3 className="text-2xl font-semibold mt-4">Usage Data</h3>
        <p className="mt-2">We collect information about how you interact with our website, including:</p>
        <ul className="list-disc ml-6 mt-2">
          <li>IP address</li>
          <li>Browser type</li>
          <li>Operating system</li>
          <li>Access times</li>
          <li>Pages viewed</li>
        </ul>
        <p className="mt-2">This information helps us analyze and improve our website and services.</p>
        
        <h3 className="text-2xl font-semibold mt-4">Cookies and Tracking Technologies</h3>
        <p className="mt-2">We use cookies, web beacons, and other tracking technologies to collect and store information about your preferences and navigation patterns. You can manage your cookie preferences through your browser settings.</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl font-semibold text-[#3E66F2]">How We Use Your Information</h2>
        <p className="mt-2">We use the information we collect to:</p>
        
        <h3 className="text-2xl font-semibold mt-4">Provide and Manage Services</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Facilitate account creation and management</li>
          <li>Process transactions and manage subscriptions</li>
          <li>Provide customer support</li>
        </ul>
        
        <h3 className="text-2xl font-semibold mt-4">Communicate</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Send administrative information, updates, and marketing communications</li>
          <li>You can opt-out of marketing emails through the unsubscribe link in the emails</li>
        </ul>
        
        <h3 className="text-2xl font-semibold mt-4">Improve Services</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Analyze usage trends and user behavior</li>
          <li>Enhance our website and services</li>
        </ul>
        
        <h3 className="text-2xl font-semibold mt-4">Compliance</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Ensure compliance with legal obligations</li>
          <li>Protect the security and integrity of our website and services</li>
        </ul>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl  text-[#3E66F2] font-semibold">Disclosure of Your Information</h2>
        <p className="mt-2">We may disclose your information to:</p>
        
        <h3 className="text-2xl font-semibold mt-4">Service Providers</h3>
        <p className="mt-2">Third-party vendors performing services on our behalf (e.g., payment processing, data analysis, email delivery, hosting, and customer support)</p>
        
        <h3 className="text-2xl font-semibold mt-4">Business Transfers</h3>
        <p className="mt-2">Potential buyers or investors in the event of a merger, sale, or other business transaction</p>
        
        <h3 className="text-2xl font-semibold mt-4">Legal Requirements</h3>
        <p className="mt-2">Comply with legal obligations</p>
        <p>Protect our rights and ensure the safety of our users</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">Data Security</h2>
        <p className="mt-2">We implement administrative, technical, and physical security measures to protect your personal information. However, no security system is impenetrable, and we cannot guarantee the security of your information.</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">Your Privacy Rights</h2>
        <p className="mt-2">Depending on your location, you may have the following rights regarding your personal information:</p>
        
        <h3 className="text-2xl font-semibold mt-4">Access and Portability</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Request access to your personal data and receive a copy in a portable format</li>
        </ul>
        
        <h3 className="text-2xl font-semibold mt-4">Correction and Deletion</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Request correction of inaccurate data</li>
          <li>Request deletion of personal data under certain conditions</li>
        </ul>
        
        <h3 className="text-2xl font-semibold mt-4">Objection and Restriction</h3>
        <ul className="list-disc ml-6 mt-2">
          <li>Object to the processing of your personal data</li>
          <li>Request restriction of processing under certain conditions</li>
        </ul>
        
        <h3 className="text-2xl font-semibold mt-4">Additional Rights for European Union Residents</h3>
        <p className="mt-2">Right to lodge a complaint with a supervisory authority if you believe your rights under GDPR have been violated</p>
        
        <p className="mt-2">To exercise your rights, please contact us using the information provided in Section 11.</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">International Data Transfers</h2>
        <p className="mt-2">We may transfer your personal information to countries outside of your residence, including to the United States and India. We take appropriate measures to ensure that your data is treated securely and in accordance with this Privacy Policy, including the use of standard contractual clauses or other legal mechanisms.</p>
      </section>
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">Use of Google Workspace APIs</h2>
        <p className="mt-2">We do not use any user data obtained through Google Workspace APIs to develop, improve, or train generalized AI and/or ML models. Our use of Google Workspace APIs is strictly limited to the scope necessary to provide and improve our services as described in this privacy policy.</p>
      </section>
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">Retention of Your Information</h2>
        <p className="mt-2">We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">Changes to This Privacy Policy</h2>
        <p className="mt-2">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on this page with a new effective date. You are advised to review this Privacy Policy periodically for any changes.</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">Additional Information</h2>
        
        <h3 className="text-xl font-semibold mt-4">Third-Party Links</h3>
        <p className="mt-2">Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these third-party sites.</p>
        
        <h3 className="text-2xl font-semibold mt-4">Children's Privacy</h3>
        <p className="mt-2">Our services are not intended for individuals under the age of 13 in the United States and India, or under the age of 16 in the European Union. We do not knowingly collect personal information from children under these age thresholds. If we become aware that we have collected personal information from a child under the applicable age, we will take steps to delete such information.</p>
      </section>
      
      <section className="mt-8 text-lg">
        <h2 className="text-3xl text-[#3E66F2] font-semibold">How to Contact Us</h2>
        <p className="mt-2">If you have any questions, concerns, or comments about this Privacy Policy or our privacy practices, you may contact us using the information below:</p>
        <p className="mt-2"><strong>Email:</strong> <a href="mailto:legal@success4.us" className="text-blue-500">legal@success4.us</a></p>
        <p className="mt-2"><strong>Mailing Address:</strong> Success4, Inc., 1525 McCarthy Blvd, Milpitas, CA 95035</p>
        <p className="mt-2"><strong>Phone:</strong> +1-415-938-6456</p>
        <p className="mt-2">We are committed to resolving complaints about our collection or use of your personal information. If you have any questions regarding your rights under this Privacy Policy, please contact us using the details provided above.</p>
      </section>
      
      {/* <p className="text-sm text-gray-600 mt-8">Effective Date: [Insert Date]</p> */}
    </div>
  );
};

export default PrivacyPolicy;
