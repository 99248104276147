import React, { useState } from 'react';
import { IoMenuOutline, IoCloseOutline, IoChevronDownOutline } from 'react-icons/io5';
import s4Logo from '../../assets/s4Logo.png';
import mobileS4Logo from '../../assets/S4Newlogo.svg';
import { pages } from '../../Components/pages/Page/metadata';
import RequestDemo from '../../Components/CommonFunction/PopHover/RequestDemo';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
  const {hoverDropdown,toggleDropdown,openDropdown,isHovered,mobileToggleDropdown,openMobileDropdown,scrollToContact,id}=props
  const [isPopupVisible, setPopupVisible] = useState(false);
  const navigate = useNavigate()

  const handleRequestDemoClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const metadataIntial =(label)=>{
        const checkEquality = navItems.map((data,key)=>{
              if(data.label==label){
                let datas= Object.values(data.subItems)[0]
                return datas[0].id
              } 
              else null
        })
      
        return checkEquality
  }



  const navItems = [
    {
      label: 'Solutions',
      subItems: {
        0: [
          { id: 'customer360', label: 'Customer 360', link: '/customer360', },
          { id: 'preventchurn', label: 'Prevent Churn', link: '/prevent-churn' },
          { id: 'customeronboarding', label: 'Customer Onboarding', link: '/customer-onboarding' },
          { id: 'hightouchcustomersuccess', label: 'High Touch Customer Success', link: '/high-touch-customer-success' },
          { id: 'techtouchcustomersuccess', label: 'Tech Touch Customer Success', link: '/tech-touch-customer-success' },
          { id: 'customerexpansion', label: 'Customer Expansion', link: '/customer-expansion' },
          { id: 'renewalmanagement', label: 'Renewal Management', link: '/renewal-management' },
          { id: 'riskmanagement', label: 'Risk Management', link: '/risk-management' },
        ],
        1: [
          { id: 'customerhealth', label: 'Customer Health', link: '/customer-health' },
          { id: 'successplanning', label: 'Success Planning', link: '/success-planning' },
          { id: 'productadoption', label: 'Product Adoption', link: '/product-adoption' },
          { id: 'customercentricsolution', label: 'Customer Centric Solution', link: '/customer-centric-solution' },
          { id: 'customercollaboration', label: 'Customer Collaboration', link: '/customer-collaboration' },
          { id: 'stakeholderalignment', label: 'Stakeholder Alignment', link: '/stakeholder-alignment' },
          { id: 'voiceofcustomer', label: 'Voice of the Customer', link: '/voice-of-customer' },
          { id: 'aiemailgenerator', label: 'AI Email Generator', link: '/ai-email-generator' },
        ]
        // Add more subItems as needed
      },
    },
    {
      label: 'Products',
      subItems: {
        0: [
          { id: 'dataintegration', label: 'Data Integration', link: '/data-integration' },
          { id: 'uibuilder', label: 'UI Builder', link: '/ui-builder' },
          { id: 'triggeredalerts', label: 'Triggered Alerts', link: '/triggered-alerts' },
          { id: 'externalprocessautomation', label: 'External Process Automation', link: '/external-process-automation' },
          { id: 'playbook', label: 'Playbooks', link: '/playbook' },
          // Add more subItems as needed
        ],
        1: [
          { id: 'successplan', label: 'Success Plan', link: '/success-plan', },
          { id: 'customdataattribute', label: 'Custom Data Attribute', link: '/custom-data-attribute', },
          { id: 'calendaremailintegration', label: 'Calendar/Email Integration', link: '/calendar-email-integration', },
          { id: 'kpis', label: 'Key Performance Indicators', link: '/kpis', },
          { id: 'sponsortracking', label: 'Sponsor Tracking', link: '/sponsor-tracking', },
          // Add more subItems as needed
        ],
      }
    },
    {
      label: 'Personas',
      subItems: {
        0: [
          { id: 'customersuccessoperation', label: 'Customer Success Operations', link: '/customer-success-operations' },
          { id: 'executives', label: 'Executives – CXO & VP', link: '/executives-cxo' },
          { id: 'customersuccessmanager', label: 'Customer Success Manager', link: '/customer-success-manager' },
          // Add more subItems as needed
        ],
      }
    },
    {
      label: 'Resources',
      subItems: {
        0: [
          { label: 'Blog', link: '/blog' },
          { label: 'Support', button: scrollToContact },
          { label: 'Career',link: '/career' },
          // Add more subItems as needed
        ]
      }
    },
  ];
  const [path, setPath] = useState()
  const [metadata, setMetaData] = useState(pages['customer360']);
  const fetchMetaData = (id, link) => {
    setPath(link)
    setMetaData(pages[id]);
  };

  const handleSubItemHover = (id, link) => {
    fetchMetaData(id, link);
    window.scrollTo({top:0, behavior:'smooth'})
  };

  return (
    <div className='sticky top-0 z-50 relative bg-white  '>
      <header className='flex h-[64px] justify-between items-center md:justify-center max-w-screen border-b border-solid border-gray-100 lg:px-[10%] px-[2%] cursor-pointer'  >
        
        <div onClick={()=>navigate('/')} className='lg:hidden md:hidden sm:block'>
          <img className='h-5' src={mobileS4Logo} alt='Mobile Logo' />
        </div>
        <div className='hidden md:flex md:space-x-3 lg:space-x-12 items-center h-[64px] '>
          {navItems.map((item, index) => (
            index<3&&<div
              className='group relative  cursor-pointer'            
              key={index}                
            >
              <div
                
                className='flex items-center text-xs md:text-[15px] focus:outline-none hover:text-gray-500  '
                onClick={() => toggleDropdown(item.label)}
                onMouseEnter={() => {hoverDropdown(item.label) }}       
                          
              >
                {item.label}
                <IoChevronDownOutline className="text-md md:text-md ml-1 transition-transform duration-400 hover:rotate-180" />
              </div>
              {
                (openDropdown === item.label || isHovered === item.label || openMobileDropdown === item.label) && (!openMobileDropdown && item.label === 'Resources' || !openMobileDropdown && item.label === 'Personas')
                && (
                  <div className='absolute mt-6'>
                    <div className=' w-[15vw]  bg-white rounded shadow-lg  cursor-pointer '>

                      {Object.values(item.subItems).flatMap((subItemList, sublistIndex) => (
                        <div key={sublistIndex}>
                          <ul className="hidden mb-4 md:mb-0 md:block  cursor-pointer" aria-labelledby="mega-menu-full-image-button">
                            {subItemList.map((subItem, keySubitems) => (
                              <li key={keySubitems} onMouseEnter={() => handleSubItemHover(subItem.id, subItem.link)} className={`px-5 py-2 hover:bg-ghostWhite transition-colors duration-300 rounded-lg`} >
                                <div  onClick={subItem.link?()=>navigate(subItem.link):subItem.button} className='text-sm' >
                                  {subItem.label}
                                </div>
                              </li>
                            ))}
                          </ul>

                        </div>
                      ))}
                    </div>
                  </div>
                )}
              
            </div>
          ))}
          <div onClick={()=>navigate('/')} className='hidden  xs:hidden sm:hidden md:block lg:block'>
          <img className='w-[220px]' src={s4Logo} alt='Company Logo' />
        </div>
        {navItems.map((item, index) => (
            index==3&&<div
              className='group relative  cursor-pointer'            
              key={index}                
            >
              <div
                
                className='flex items-center text-xs md:text-[15px] focus:outline-none hover:text-gray-500  '
                onClick={() => toggleDropdown(item.label)}
                onMouseEnter={() => {hoverDropdown(item.label) }}       
                          
              >
                {item.label}
                <IoChevronDownOutline className="text-md md:text-md ml-1 transition-transform duration-400 hover:rotate-180" />
              </div>
              {
                (openDropdown === item.label || isHovered === item.label || openMobileDropdown === item.label) && (!openMobileDropdown && item.label === 'Resources' || !openMobileDropdown && item.label === 'Personas')
                && (
                  <div className='absolute mt-6'>
                    <div className=' w-[15vw]  bg-white rounded shadow-lg  cursor-pointer '>

                      {Object.values(item.subItems).flatMap((subItemList, sublistIndex) => (
                        <div key={sublistIndex}>
                          <ul className="hidden mb-4 md:mb-0 md:block  cursor-pointer" aria-labelledby="mega-menu-full-image-button">
                            {subItemList.map((subItem, keySubitems) => (
                              <li key={keySubitems} onMouseEnter={() => handleSubItemHover(subItem.id, subItem.link)} className={`px-5 py-2 hover:bg-ghostWhite transition-colors duration-300 rounded-lg`} >
                                <div  id={id} onClick={subItem.link?()=>navigate(subItem.link):subItem.button} className='text-sm' >
                                  {subItem.label}
                                </div>
                              </li>
                            ))}
                          </ul>

                        </div>
                      ))}
                    </div>
                  </div>
                )}
              
            </div>
          ))}

          <div className='flex items-center md:font-normal md:space-x-1 lg:space-x-3 h-[64px]'>
            <button
              type='button'
              onClick={() => navigate('/pricing')}
              className='text-sm md:text-[15px] lg:text-[15px] xl:text-[15px] hover:text-grayColor  cursor-pointer'
            >
              Pricing
            </button>
          </div>
          <div className='flex items-center md:space-x-1 lg:space-x-2 xl:space-x-2 text-black h-[64px]'>
            <button
              type='button'
              onClick={() => navigate('/about')}
              className='text-sm md:text-[15px] lg:text-[15px] xl:text-[15px] hover:text-grayColor  cursor-pointer'
            >
              About Us
            </button>
          </div>
          {/* <div className='sm:hidden md:block md:flex md:items-center md:space-x-1 lg:space-x-2 xl:space-x-2 text-black h-[64px]'>
            <button
              type='button'
              onClick={handleRequestDemoClick}
              className='text-sm md:text-[15px] lg:text-[15px] xl:text-[15px] hover:bg-[#3E67F1] hover:py-[10px] hover:px-[15px] py-[9px] px-[12px] bg-blueColor rounded-[5px] text-white  cursor-pointer'
            >
              Request Demo
            </button>
          </div> */}
        </div>
        <div className='md:hidden flex items-center justify-end pr-4'>
          <button className='py-[9px] px-[12px] bg-blueColor rounded-[5px] text-white hover:py-[10px] hover:px-[15px]  cursor-pointer' onClick={handleRequestDemoClick}>Request Demo</button>
          <button
            onClick={toggleMobileMenu}
            className='text-black hover:text-grayColor focus:outline-none flex items-center'
          >
            {isMobileMenuOpen ? <IoCloseOutline className='w-[40px] h-[40px]' /> : <IoMenuOutline className='w-[40px] h-[40px]' />}
          </button>
        </div>
      </header>

      {navItems.map((item, itemIndex) => {
        if ((item.label === openDropdown || isHovered === item.label) &&
          item.label !== 'Resources' &&
          item.label !== 'Personas') {
          return (
            <div key={itemIndex} id="mega-menu-full-image-dropdown" className="  cursor-pointer absolute w-full p-5 bg-white border-gray-200 shadow-sm border-y ">
              <div className="grid max-w-screen-xl  mx-auto text-sm text-gray-500 dark:text-gray-400 md:grid-cols-4 gap-5 md:px-6">
                <div className='grid grid-cols-2 col-span-2 '>
                  {Object.values(item.subItems).flatMap((subItemList, sublistIndex) => (
                    <div key={sublistIndex}>
                      <ul className="hidden mb-4 md:mb-0 md:block " aria-labelledby="mega-menu-full-image-button">
                        {subItemList.map((subItem, keySubitems) => (
                          <li key={keySubitems} onMouseEnter={() => handleSubItemHover(subItem.id, subItem.link)} className={`px-12 py-2 hover:bg-ghostWhite transition-colors duration-300 rounded-lg`} >
                            <div className='pointer' onClick={()=>{navigate(subItem.link)}}>
  {subItem.label}
</div>
                          </li>
                        ))}
                      </ul>

                    </div>
                  ))}
                </div>

                {metadata?.map((sectionList, sectionKey) => {
                  return (
                    <div key={sectionKey}  className="grid grid-cols-2  border-l-2 border-gray-200 col-span-2 px-8 ">
                      <div >
                        <h3 className='text-xl text-center md:text-left text-black pt-[20px]  font-bold '>{sectionList.headersection.h3Content}</h3>
                        {sectionList.headersection.h6Content.map(description => {
                          return (
                            <h6 className='text-2xl mt-[15px] mb-[28px] text-textGrayColor'>{description.h6}</h6>
                          )
                        })}
                        <div className='mt-4 text-center md:text-left'>
                          <div onClick={()=>{navigate(path)}}  className='cursor-pointer my-2 mx-0 inline-block text-white rounded-md px-3 py-2 text-base bg-blueColor shadow-sm'>View Details</div>
                        </div>
                      </div>
                      <img src={sectionList?.headersection?.image} />
                    </div>
                  )
                })}
              </div>
            </div>
          );
        } else {
          return null; // If label doesn't match, return null or other fallback
        }
      })}
      {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
      {isMobileMenuOpen && (
        <div className='md:hidden  bg-black max-w-screen '>
          <div className='flex flex-col px-[15px]'>
            {navItems.map((item, index) => (
              <div>
                <div key={index} className='flex py-[10px] justify-between text-white'>
                  <button
                    type='button'                    
                    onMouseEnter={() => mobileToggleDropdown(null)}                                    
                    className='text-xs md:text-md'
                  >
                    {item.label}
                  </button>
                  <div className='text-xs md:text-md' >
                    <IoChevronDownOutline onClick={() => mobileToggleDropdown(item.label)}  />
                  </div>
                </div>
                {/* Subitems */}
                {(item.label === openMobileDropdown || isHovered === item.label) && (
                  <ul className="mb-4 md:mb-0 md:block bg-white text-black" aria-labelledby="mega-menu-full-image-button">
                    {Object.values(item.subItems).flatMap((subItemList, sublistIndex) => (
                      <div key={sublistIndex}>
                        {subItemList.map((subItem, keySubitems) => (
                          <li key={keySubitems} onMouseEnter={() => handleSubItemHover(subItem.id, subItem.link)} className={`px-5 text-sm py-2 hover:bg-ghostWhite transition-colors duration-300 rounded-lg`} >
                            <div className='cursor-pointer' onClick={()=>{navigate(subItem.link),mobileToggleDropdown(null)}} >
                              {subItem.label}
                            </div>
                          </li>
                        ))}
                      </div>
                    ))}
                  </ul>
                )}

              </div>
            ))}

            {/* Additional buttons */}
            <div className='flex py-[10px] justify-between text-white'>
              <button
                type='button'
                onClick={() => navigate( '/pricing')}
                className='text-xs md:text-md'
              >
                Pricing
              </button>
            </div>
            <div className='flex py-[10px] justify-between text-white'>
              <button
                type='button'
                onClick={() => navigate( '/about')}
                className='text-xs md:text-md'
              >
                About Us
              </button>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
