import React, { useState, useEffect, useRef } from 'react'
import BannerImg from '../../../assets/bannerimgmobile.svg'
import Homecurve from '../../../assets/Homecurve.svg'
import Homecurvewhite from '../../../assets/Homecurvewhite.svg'
import EasyIcon from '../../../assets/easy-icon.svg'
import SecureIcon from '../../../assets/secure-icon.svg'
import InsightIcon from '../../../assets/insightfull-icon.svg'
import FieldAssist from '../../../assets/trustedby/FieldAssist-Logo.png'
import Adapt from '../../../assets/trustedby/adapt-ai.png'
import ExtraEdge from '../../../assets/trustedby/extra-edge.png'
import Decisionminds from '../../../assets/trustedby/decisionminds.png'
import Iquanti from '../../../assets/trustedby/iquanti-s4.png'
import Joinneight from '../../../assets/trustedby/joineight-s4.png'
import Leenaai from '../../../assets/trustedby/leenaai-s4.png'
import MovieSync from '../../../assets/trustedby/moveinsync-logo-s4.png'
import Opsramp from '../../../assets/trustedby/opsramp-s4.png'
import Recko from '../../../assets/trustedby/recko-s4.png'
import SendSafely from '../../../assets/trustedby/sendsafely-s4.png'
import Singleops from '../../../assets/trustedby/singleops-s4.png'
import TalView from '../../../assets/trustedby/talview-s4.png'
import ImgFast from '../../../assets/img_fastest_value.svg'
import Imgscale from '../../../assets/img_scale.svg'
import Imghybrid from '../../../assets/img_hybrid_cloud.svg'
import ImgPredictiveAnalytics from '../../../assets/img_predictive_analytics.svg'
import Joveo from '../../../assets/trustedby/joveo.svg'
import FactSuite from '../../../assets/trustedby/factsuite-logo.png'
import RequestDemobg from '../../../assets/RequestDemobg.svg'
import Stages from './Stages/Stages'
import RequestDemo from '../../CommonFunction/PopHover/RequestDemo'
import TwoGridCol from '../../CommonFunction/TwoGridCol/TwoGridCol'
import Soctypebadge from '../../../assets/Soctypecredit.svg'
import Vaptbadge from '../../../assets/Vaptbadge.svg'
import { Link } from 'react-router-dom'
import Scroll from '../../CommonFunction/Scroll/Scroll'
import '../../CommonFunction/Common.css'
import RequestDemoBtn from '../../CommonFunction/Button/RequestDemoBtn'

const imageComponents = [
  { src: Decisionminds, alt: 'img1' },
  { src: Adapt, alt: 'img2' },
  { src: FieldAssist, alt: 'img3' },
  { src: ExtraEdge, alt: 'img4' },
  { src: Iquanti, alt: 'img5' },
  { src: TalView, alt: 'img6' },
  { src: Joinneight, alt: 'img7' },
  { src: MovieSync, alt: 'img8' },
  { src: Leenaai, alt: 'img9' },
  { src: Opsramp, alt: 'img10' },
  { src: Recko, alt: 'img11' },
  { src: SendSafely, alt: 'img12' },
  { src: Singleops, alt: 'img13' },
  { src: Joveo, alt: 'img14' },
  { src: FactSuite, alt: 'img15' }
];

const Home = () => {

  const [isPopupVisible, setPopupVisible] = useState(false);
  const boxcontainerRef = useRef(null);

  const handleRequestDemoPopup = (status) => {
    setPopupVisible(status);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const s4List = [
    {
      Title: 'Fastest Time to Value',
      Image: ImgFast,
      Order: { one: 'lg:order-1 -translate-x-1/2', two: 'lg:order-2 translate-x-1/2' },
      Description: 'Quickly connect to your customer data and onboard your team. Easily customize the experience for your business. Leverage our out of the box best practices and templates. Be up and running in less than a week.',
      List: ['Data Integrations', 'Drag & Drop UI Builder', 'Playbook templates']
    },
    {
      Title: 'Scale to reach every customer',
      Image: Imgscale,
      Order: { one: 'lg:order-2   translate-x-1/2 ', two: 'lg:order-1 -translate-x-1/2' },
      Description: 'Easily automate tech touch customer success through external process automation, triggers, and workflows. Leverage meaningful insights to focus your team’s efforts on the highest value activities with your highest value customers through success plans, KPIs, and playbooks',
      List: ['Success Plan', 'Playbook', 'Triggers', 'Automation']
    },
    {
      Title: 'Powerful Predictive Analytics',
      Image: ImgPredictiveAnalytics,
      Order: { one: 'lg:order-1 -translate-x-1/2', two: 'lg:order-2  translate-x-1/2' },
      Description: 'Data science modeling, KPIs, and health scores give you powerful predictive insights into your customers. Triggers and alerts prescribe the right actions at the right times.',
      List: ['Report Visualizations', 'Triggers', 'KPIs', 'Alerts', 'Health Scores']
    },
    {
      Title: 'Industry’s only hybrid cloud solution',
      Image: Imghybrid,
      Order: { one: 'lg:order-2   translate-x-1/2 ', two: 'lg:order-1 -translate-x-1/2' },
      Description: 'On premise, your cloud, or our cloud, flexibly choose where you want your data to live and how you want your solution operated.',
      List: ['Customer Data Security', 'Hybrid Cloud', 'Firewall Protected']
    }
  ]


  useEffect(() => {
    const Sliders = boxcontainerRef.current.querySelectorAll(".boxslide-in");

    const appearOptions = {
      threshold: 0,
      rootMargin: "0px"
    };

    const appearOnScroll = (entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("appear");
        } else {
          entry.target.classList.remove("appear");
        }
      });
    };

    const observer = new IntersectionObserver(appearOnScroll, appearOptions);

    Sliders.forEach((slider, index) => {
      slider.style.transitionDelay = `${index * 0.2}s`;
      observer.observe(slider);
    });

    // Clean up function
    return () => {
      observer.disconnect();
    };
  }, []);

  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    // When the component mounts, set the visibility to true to trigger the animation
    setIsVisible(true);
  }, []);
  return (
    <>
      <div className='sm:block font-nunito-sans pt-20 xl:bg-center  xl:bg-cover xl:h-[calc(100%-20%)] xl:bg-cover xl:bg-center bg-custom' style={{ backgroundImage: 'url("/Homebg.svg")' }} >
        <div className='sm:w-full   h-full xl:items-center xl:justify-center flex flex-col '>
          <div className={` sm:w-full xl:w-[55vw]  transition-transform duration-500 ${isVisible ? 'animate-zoom-in' : ''} px-5 flex flex-col items-center justify-center w-[4 
          0%]`}>
            <div id="content">
              <div className='lg:text-6xl md:text-5xl text-4xl text-center  font-bold'>
                <span className='text-blueColor'> Understand </span> your customers better
              </div>
              <div className='mt-3 md:mt-5 font-thin'>
                <h4 className='text-base sm:text-lg text-center text-textGrayColor'>
                  We provide the right tools that do all the heavy lifting so you can focus on your customer success strategy.
                </h4>
              </div>
            </div>
            <div className='mt-6 text-center  max-w-full hover:scale-110 transition-transform duration-300'>
              <RequestDemoBtn handleRequestDemoPopup={handleRequestDemoPopup} isContainer={false} isIcon={false} />
            </div>

          </div>
          <div className={`sm:flex items-center justify-center   ${isVisible ? 'animate-slide-up' : ''}`}>
          <img className='w-[1600px] hover:scale-105 transition-transform' src={BannerImg} alt='Banner Image' />
          </div>

          <div className='font-nunito-sans pt-[50px] w-full xl:w-[60%] flex items-center justify-center flex-col'>
            <div className=' mb-[20px]'>
              <div className='mt-[10px] text-center font-bold text-[25px]'>Trusted by</div>
            </div>
            <section className=' w-full'>
              <div className='relative box-border overflow-hidden flex items-center fixed h-[15vh]'>
                <Scroll images={imageComponents} />
              </div>
              <div className='flex items-center justify-center '>
                {isPopupVisible && <RequestDemo onClose={handleClosePopup} />}
              </div>
            </section>
          </div>

        </div>
        <img className='w-full' src={Homecurve} />
      </div>

      <div className='font-nunito-sans bg-ghostWhite leading-[35px]'>
        <section className='text-center w-full leading-[35px]'>
          <div>
            <h3 className='text-[26px] font-bold'>Our Core Values</h3>
          </div>
        </section>
        <section ref={boxcontainerRef} className=' lg:gap-12 gap-0 grid lg:grid-row-3 lg:grid-flow-col text-center text-grayColor flex justify-center items-center '>
        <div className='lg:w-[24vw] w-full flex justify-center from-bottom boxslide-in duration-100'>
            <div className='absolute mb-4 '>
              <img src={EasyIcon} className='mx-auto' alt='Easy Icon' />
            </div>
            <div className='mt-16 w-[60%] lg:w-full xl:px-14 lg:px-6 px-4 py-6 flex flex-col gap-4 bg-white border border-solid border-gray-300 rounded-md'>
              <h3 className='mt-16 mx-auto text-[32px] font-bold '>Easy</h3>
              <div className='lg:h-[23vh] flex flex-col justify-between'>
                <p className='text-[18px]'>Easy to use, deploy, and customize to your businesss needs</p>
                <Link to='/about' className='text-[18px] text-blueColor font-semibold'>Know more</Link>
              </div>
            </div>
          </div>

          <div className='lg:w-[24vw] w-full flex justify-center from-bottom boxslide-in duration-200 '>
            <div className='absolute mb-4 '>
              <img src={SecureIcon} className=' mx-auto ' />
            </div>
            <div className='mt-16 w-[60%] lg:w-full 2xl:px-14  xl:px-4 px-[0.5px] py-6 flex flex-col gap-4 bg-white border border-solid border-gray-300 rounded-md'>
              <h3 className='mt-16  mx-[auto] text-[32px] font-semibold '>Secure</h3>
              <div className='lg:h-[23vh] flex flex-col justify-between'>
                <p className='text-[18px]'>The industry’s only hybrid cloud solution (so your customer data never leaves your cloud)</p>
                <Link to='/about' className='text-[18px] text-blueColor font-semibold'>Know more</Link>
              </div>
            </div>
          </div>
          <div className='lg:w-[24vw] w-full flex justify-center   from-bottom boxslide-in duration-300'>
            <div className='absolute mb-4 '>
              <img src={InsightIcon} className=' mx-auto ' />
            </div>
            <div className='mt-16 w-[60%] lg:w-full xl:px-14 lg:px-6 px-4 py-6  flex flex-col gap-4 bg-white border border-solid border-gray-300 rounded-md'>
              <h3 className='mt-16 mx-[auto] text-[32px] font-semibold '>Insightful</h3>
              <div className='lg:h-[23vh] flex flex-col justify-between '>
                <p className='text-[18px]'>A unified view of your customers and how to best serve them</p>
                <Link to='/about' className='text-[18px]  text-blueColor font-semibold '>Know more</Link>
              </div>
            </div>
          </div>
        </section>
        <img className='w-full' src={Homecurvewhite} />
      </div>

      <div >
        <TwoGridCol listData={s4List} />
      </div>
      <div>
        <Stages />
      </div>
      <section >
        <div className='font-nunito-sans flex mx-auto relative'>
          <div className='w-full '>
            <h2 className='text-center text-[25px] font-bold mt-[6vh] mb-[3vh] mx-auto'>Security Certification & Compliance</h2>
            <section className='pb-[30px]  flex items-center justify-center '>
              <div className=' mx-auto lg:flex lg:text-center lg:w-[25vw] '>
                <div className='lg:w-[45vw] flex text-sm gap-2 items-center justify-center'><img src={Soctypebadge}/><div >SOC2 TYPE II CERTIFIED</div></div>
                <div className='lg:w-[45vw] flex text-sm gap-2 items-center justify-center'><img src={Vaptbadge}/><div >VAPT COMPLIANT</div></div>
              </div>
            </section>

          </div>
        </div>
      </section>
    <div className=' md:px-[10%] px-[2%] h-[300px]'>
    <RequestDemoBtn handleRequestDemoPopup={handleRequestDemoPopup} isContainer={true} isIcon={true} h3Content={`customers better`}/>
    </div>
      {/* need to collect the badge */}
      <section>
        {/* <div className='font-nunito-sans md:flex md:items-center md:justify-center flex flex-col'>
          <div className='flex justify-between gap-10'>
            <img src={Leenaai} className='lg:h-[60px] lg:max-w-[200px]'/>
            <img src={MovieSync} className='lg:h-[60px] lg:max-w-[200px]'/>
          </div>
        </div> */}
      </section>
    </>
  )

}

export default Home